import { Progress } from "@chakra-ui/react";

interface FidelityProgramProgressBarProps {
  value: number;
}

export function FidelityProgramProgressBar({
  value,
}: FidelityProgramProgressBarProps) {
  return (
    <Progress
      colorScheme="green"
      height="5px"
      value={value}
      min={0}
      max={10}
      zIndex="2"
    />
  );
}
