import { Stack } from "@chakra-ui/react";
import { OutlineListButton } from "../../atoms/Button/OutlineListButton";
import { SolidListButton } from "../../atoms/Button/SolidListButton";
import { InternalLink } from "../../atoms/Link/InternalLink";

interface LoaderClientSearchButtonListProps {
  storeLink: string;
  loginLink: string;
  thanksLink: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  uniqueAnswerClient: boolean;
  anonymousSearch: boolean;
  reverseOrder: boolean;
  formLink: string;
}

export function LoaderClientSearchButtonList({
  storeLink,
  loginLink,
  textColor,
  primaryColor,
  thanksLink,
  secondaryColor,
  anonymousSearch,
  formLink,
  reverseOrder,
  uniqueAnswerClient,
}: LoaderClientSearchButtonListProps) {
  return (
    <Stack
      alignItems="center"
      flexDir="column"
      justify="space-between"
      w="100%"
    >
      <InternalLink link={storeLink}>
        <SolidListButton
          bg={primaryColor}
          text="Cadastrar-se"
          color={textColor}
        />
      </InternalLink>
      {uniqueAnswerClient === false && (
        <InternalLink link={loginLink}>
          <SolidListButton
            bg={secondaryColor}
            text="Já fiz meu cadastro"
            color={textColor}
          />
        </InternalLink>
      )}
      {!anonymousSearch && !reverseOrder && (
        <InternalLink link={thanksLink}>
          <OutlineListButton text="Não se identificar" color={primaryColor} />
        </InternalLink>
      )}
      {!anonymousSearch && reverseOrder && (
        <InternalLink link={formLink}>
          <OutlineListButton text="Não se identificar" color={primaryColor} />
        </InternalLink>
      )}
    </Stack>
  );
}
