import { useDisclosure, Modal, ModalContent } from "@chakra-ui/react";
import { useMemo } from "react";
import { GiftVisitCard } from "../../../molecules/GiftVisitCard";
import { ModalBonus } from "../../../organisms/Modal/ModalBonus";

interface Bonus {
  total_visits: number;
  name: string;
}
interface GiftBonusModalCardProps {
  buttonBg: string;
  value: string;
  bonuses: Bonus[];
  visit: number;
}

export function GiftBonusModalCard({
  value,
  buttonBg,
  bonuses,
  visit,
}: GiftBonusModalCardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bonus = useMemo(() => {
    return (
      bonuses.find((bonus) => bonus.total_visits === visit) ?? ({} as Bonus)
    );
  }, [bonuses, visit]);
  return (
    <>
      <GiftVisitCard value={value} onOpen={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
        <ModalContent>
          <ModalBonus
            color={buttonBg}
            subtitle={`${bonus.total_visits}° visita`}
            title={bonus.name}
          />
        </ModalContent>
      </Modal>
    </>
  );
}
