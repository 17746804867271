import { FormControlFieldset } from "../../atoms/FormControl/FormControlFieldset";
import { FormLabelLegend } from "../../atoms/FormLabel/FormLabelLegend";
import { UnstyledInput } from "../../atoms/Input/UnstyledInput";
import { useState } from "react";
import { InputProps } from "@chakra-ui/react";

interface IProps extends InputProps {
  name: string;
  selectColor: string;
  error: string;
  setValue: (event: any) => void;
  defaultValue?: string;
}

export function InputGroup(props: IProps) {
  const [focus, setFocus] = useState<boolean>(false);
  return (
    <FormControlFieldset
      bg={props.selectColor}
      id="ter"
      error={!!props.error}
      focus={focus}
    >
      <FormLabelLegend
        htmlFor="promotor"
        text="Digite sua resposta"
        color={props.selectColor}
        focus={focus}
      />
      <UnstyledInput
        id={`input-${props.name}`}
        onFocus={() => {
          setFocus(true);
        }}
        name={props.name}
        onBlur={(event) => {
          props.setValue(event);
          setFocus(false);
        }}
        isRequired={props.isRequired}
        defaultValue={props.defaultValue}
      />
    </FormControlFieldset>
  );
}
