import React, { ReactNode } from "react";

import {
  FormControl as ChakraFormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";

interface FormControlProps {
  name: string;
  label?: string;
  error?: any;
  isRequired?: boolean;
  children: ReactNode;
  description?: string;
}

export function FormControl({
  name,
  label,
  isRequired = false,
  children,
  description,
  error = undefined,
}: FormControlProps) {
  return (
    <ChakraFormControl isRequired={isRequired} isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {description && (
        <>
          <Text fontSize="12px" color="gray.500" mt="-10px">
            {description}
          </Text>
        </>
      )}
      {children}
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </ChakraFormControl>
  );
}
