import { SubtitleDefault } from "../../../atoms/Text/SubtitleDefault";
import { FidelityProgramColorText } from "../../../atoms/Text/FidelityProgramColorText";
import { Flex } from "@chakra-ui/react";
export interface ModalGiftListTextSubtitleProps {
  subtitle: string;
  color: string;
  type: string;
}

export function ModalGiftListTextSubtitle({
  subtitle,
  color,
  type,
}: ModalGiftListTextSubtitleProps) {
  return (
    <Flex justify="space-around" align="center" flexDir="column" minH="50px">
      <FidelityProgramColorText text={type} color={color} />
      <SubtitleDefault subtitle={subtitle} />
    </Flex>
  );
}
