import { Box,useRadio, UseRadioProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IProps extends UseRadioProps {
  children: ReactNode;
  type: "like" | "dislike";
}

export function LikeDislikeRadioCheckbox(props: IProps) {
  const { getRadioProps } = useRadio(props);

  const checkout = getRadioProps();

  return (
    <Box
      {...checkout}
      cursor="pointer"
      borderWidth="1px"
      borderRadius="md"
      color={props.type === "like" ? "#41E93E" : "#E93E3E"}
      borderColor={props.type === "like" ? "#41E93E" : "#E93E3E"}
      borderStyle="solid"
      background="#fff"
      boxShadow="md"
      _checked={
        props.type === "like"
          ? {
              bg: "#41E93E",
              color: "white",
              borderColor: "#41E93E",
            }
          : {
              bg: "#E93E3E",
              color: "white",
              borderColor: "#E93E3E",
            }
      }
      _focus={{
        boxShadow: "outline",
      }}
      w="40px"
      h="40px"
      textAlign="center"
      alignContent="center"
      pt={1.5}
      mt={2}
      {...props}
    >
      {props.children}
    </Box>
  );
}
