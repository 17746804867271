import { Stack } from "@chakra-ui/react";
import { OutlineListButton } from "../../atoms/Button/OutlineListButton";
import { SolidListButton } from "../../atoms/Button/SolidListButton";
import { ExternalLink } from "../../atoms/Link/ExternalLink";
import { InternalLink } from "../../atoms/Link/InternalLink";

interface FidelityProgramButtonListProps {
  fidelityProgramLink: string;
  searchLink: string;
  external_link?: string;
  name_external_link?: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
}

export function FidelityProgramButtonList({
  external_link,
  fidelityProgramLink,
  name_external_link,
  textColor,
  primaryColor,
  searchLink,
  secondaryColor,
}: FidelityProgramButtonListProps) {
  return (
    <Stack alignItems="center" flexDir="column" justify="space-between">
      <InternalLink link={fidelityProgramLink}>
        <SolidListButton
          bg={primaryColor}
          text="Programa de Fidelidade"
          color={textColor}
        />
      </InternalLink>
      <InternalLink link={searchLink}>
        <SolidListButton
          bg={secondaryColor}
          text="Responder Pesquisa"
          color={textColor}
        />
      </InternalLink>
      {external_link && name_external_link && (
        <ExternalLink link={external_link}>
          <OutlineListButton text={name_external_link} color="#5C5C5C" />
        </ExternalLink>
      )}
    </Stack>
  );
}
