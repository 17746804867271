/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useFormClient } from "../../../hooks/formClient";
import { useQuery } from "../../../hooks/query";
import { DinamicOutilineButton } from "../../atoms/Button/DinamicOutilineButton";
import { DinamicSolidButton } from "../../atoms/Button/DinamicSolidButton";
import { SubmitButton } from "../../atoms/Button/SubmitButton";
import { SearchProgressBar } from "../../atoms/Progress/SearchProgressBar";
import { CSATRadioQuestion } from "../../organisms/RadioQuestion/CSATRadioQuestion";
import { EmoticonRadioQuestion } from "../../organisms/RadioQuestion/EmoticonRadioQuestion";
import { LikeDislikeRadioQuestionDefault } from "../../organisms/RadioQuestion/LikeDislikeRadioQuestion/LikeDislikeRadioQuestionDefault";
import { MultipleChoiceRadioQuestionDefault } from "../../organisms/RadioQuestion/MultipleChoiceRadioQuestion/MultipleChoiceRadioQuestionDefault";
import { NPSRadioQuestionDefault } from "../../organisms/RadioQuestion/NPSRadioQuestion/NPSRadioQuestionDefault";
import { RatingRadioQuestionDefault } from "../../organisms/RadioQuestion/RatingRadioQuestion/RatingRadioQuestionDefault";
import { SelectQuestionDefault } from "../../organisms/SelectQuestion/SelectQuestionDefault";
import { TextQuestionDefault } from "../../organisms/TextQuestion/TextQuestionDefault";

interface IQuestion {
  id: string;
  name: string;
  nick: string;
  required: boolean;
  others?: boolean;
  unknown?: boolean;
  description: string;
  type_csat?: "numerico" | "satisfeito";
  type:
    | "NPS"
    | "CSAT"
    | "Caixa de seleção"
    | "Multipla escolha"
    | "Rating"
    | "Emoticon"
    | "Like/Dislike"
    | "Resposta curta"
    | "Atendente";
  options?: {
    id: string;
    name: string;
    created_at: Date;
  }[];
  detractor_replica?: string;
  neutral_replica?: string;
  promoter_replica?: string;
  has_promoter_replica?: boolean;
  has_neutral_replica?: boolean;
  has_detractor_replica?: boolean;
  format: "default" | "select";
}

interface IProps {
  selectColor: string;
  questions: IQuestion[];
  handle: (data: any) => void;
}

interface ChooseQuestionProps {
  question: IQuestion;
  position: number;
}

export function QuestionSearchMultiPage({
  selectColor,
  questions,
  handle,
}: IProps) {
  const query = useQuery();
  const { search, company, replaceQuestion, questionQuery } = useFormClient();
  let countQuestionWithAnswer = 0;
  const {
    register,
    handleSubmit,
    setValue,
    formState,
    trigger,
    getValues,
    clearErrors,
  } = useForm({});
  const [responseChannel, setResponseChannel] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [updatedQuestions, setUpdatedQuestions] = useState(questions);
  const { errors } = formState;

  const handleTrigger = async (value: string) => {
    const validation = await trigger(value);
    if (validation) {
      setStep(step + 1);
    }
  };

  const updateError = async (value: string, error: boolean) => {
    const validation = await trigger(value);
    if (validation && error === false) {
      clearErrors();
    }
  };

  const handleSetValue = useCallback((id: string, value: string) => {
    countQuestionWithAnswer++;
    setValue(`quizResponses.${id}`, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionQuery) {
      const splitQuestionQuery = questionQuery.split("&");
      const questions_id = splitQuestionQuery
        .filter((q) => q)
        .map((q) => {
          const splitFinalQuery = q.split("=");
          return splitFinalQuery[0];
        });

      const formatQuestions = questions.filter((question) => {
        if (questions_id.includes(question.id)) {
          const value = query.get(question.id);
          if (value) {
            handleSetValue(question.id, value);
            return false;
          }
        }
        return true;
      });

      setUpdatedQuestions(formatQuestions);
    }
    const response = localStorage.getItem(`@B2S:link`);
    if (response) {
      setResponseChannel("QRcode");
    } else {
      setResponseChannel("Link");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.slug, questionQuery, questions, responseChannel]);

  const chooseQuestion = ({ question, position }: ChooseQuestionProps) => {
    switch (question.type) {
      case "NPS":
        return (
          <>
            <NPSRadioQuestionDefault
              error={errors && errors[`quizResponses`] ? true : false}
              updateError={updateError}
              step={step}
              setStep={(newStep) => setStep(newStep)}
              handleTrigger={handleTrigger}
              questionId={question.id}
              description={question.description}
              selectColor={selectColor}
              {...register(`quizResponses.${question.id}`, {
                required: question.required,
              })}
              label={`${position} - ${question.name
                .replace(/ Empresa ]/gi, replaceQuestion)
                .replace("[", "")}`}
              setValue={(nps: number, replica: string) => {
                if (nps === -1) {
                  setValue(`quizResponses.${question.id}`, "");
                }
                if (nps !== -1) {
                  setValue(`quizResponses.${question.id}`, nps);
                  setValue(`quizResponses_suggestion.${question.id}`, replica);
                }
              }}
              isRequired={question.required}
              has_detractor_replica={search.has_detractor_replica}
              has_neutral_replica={search.has_neutral_replica}
              has_promoter_replica={search.has_promoter_replica}
              detractor_replica={question.detractor_replica}
              neutral_replica={question.neutral_replica}
              promoter_replica={question.promoter_replica}
              defaultValue={getValues(`quizResponses.${question.id}`)}
              defaultReplica={getValues(
                `quizResponses_suggestion.${question.id}`
              )}
            />
          </>
        );
      case "Caixa de seleção":
        return query.get(question.id) !== null ? (
          <>{handleSetValue(question.id, query.get(question.id)!)}</>
        ) : (
          <MultipleChoiceRadioQuestionDefault
            error={errors && errors[`quizResponses`] ? true : false}
            updateError={updateError}
            step={step}
            setStep={(newStep) => setStep(newStep)}
            handleTrigger={handleTrigger}
            questionId={question.id}
            description={question.description}
            label={`${position} - ${question.name
              .replace(/ Empresa ]/gi, replaceQuestion)
              .replace("[", "")}`}
            options={
              question.options
                ? question.options.sort((a, b) =>
                    a.created_at < b.created_at ? -1 : 1
                  )
                : []
            }
            {...register(`quizResponses.${question.id}`, {
              required: question.required,
            })}
            isRequired={question.required}
            setValue={(value: string) => {
              setValue(`quizResponses.${question.id}`, value);
            }}
            selectColor={selectColor}
            other={question.others}
            defaultValue={getValues(`quizResponses.${question.id}`)}
          />
        );
      case "CSAT":
        return (
          <CSATRadioQuestion
            error={errors && errors[`quizResponses`] ? true : false}
            updateError={updateError}
            step={step}
            setStep={(newStep) => setStep(newStep)}
            handleTrigger={handleTrigger}
            questionId={question.id}
            description={question.description}
            selectColor={selectColor}
            label={`${position} - ${question.name
              .replace(/ Empresa ]/gi, replaceQuestion)
              .replace("[", "")}`}
            {...register(`quizResponses.${question.id}`, {
              required: question.required,
            })}
            isRequired={question.required}
            setValue={(nps: number, replica: string) => {
              if (nps === -1) {
                setValue(`quizResponses.${question.id}`, "");
              }
              if (nps !== -1) {
                setValue(`quizResponses.${question.id}`, nps);
                setValue(`quizResponses_suggestion.${question.id}`, replica);
              }
            }}
            has_detractor_replica={search.has_detractor_replica}
            has_neutral_replica={search.has_neutral_replica}
            has_promoter_replica={search.has_promoter_replica}
            detractor_replica={question.detractor_replica}
            neutral_replica={question.neutral_replica}
            promoter_replica={question.promoter_replica}
            defaultValue={getValues(`quizResponses.${question.id}`)}
            defaultReplica={getValues(
              `quizResponses_suggestion.${question.id}`
            )}
          />
        );
      case "Multipla escolha":
        if (query.get(question.id) !== null) {
          return <>{handleSetValue(question.id, query.get(question.id)!)}</>;
        }
        switch (question.format) {
          case "select":
            return (
              <SelectQuestionDefault
                error={errors && errors[`quizResponses`] ? true : false}
                updateError={updateError}
                other={question.others}
                step={step}
                setStep={(newStep) => setStep(newStep)}
                handleTrigger={handleTrigger}
                questionId={question.id}
                description={question.description}
                label={`${position} - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                options={
                  question.options
                    ? question.options.sort((a, b) =>
                        a.created_at < b.created_at ? -1 : 1
                      )
                    : []
                }
                {...register(`quizResponses.${question.id}`, {
                  required: question.required,
                })}
                isRequired={question.required}
                setValue={(value: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                }}
                selectColor={selectColor}
                defaultValue={getValues(`quizResponses.${question.id}`)}
              />
            );
          case "default":
            return (
              <MultipleChoiceRadioQuestionDefault
                error={errors && errors[`quizResponses`] ? true : false}
                updateError={updateError}
                step={step}
                setStep={(newStep) => setStep(newStep)}
                handleTrigger={handleTrigger}
                questionId={question.id}
                description={question.description}
                label={`${position} - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                options={
                  question.options
                    ? question.options.sort((a, b) =>
                        a.created_at < b.created_at ? -1 : 1
                      )
                    : []
                }
                {...register(`quizResponses.${question.id}`, {
                  required: question.required,
                })}
                isRequired={question.required}
                setValue={(value: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                }}
                selectColor={selectColor}
                other={question.others}
                defaultValue={getValues(`quizResponses.${question.id}`)}
              />
            );
          default:
            return <></>;
        }
      case "Atendente":
        if (query.get(question.id) !== null) {
          return <>{handleSetValue(question.id, query.get(question.id)!)}</>;
        }
        switch (question.format) {
          case "select":
            return (
              <SelectQuestionDefault
                type="Atendente"
                unknown={question.unknown}
                other={question.others}
                error={errors && errors[`quizResponses`] ? true : false}
                updateError={updateError}
                step={step}
                setStep={(newStep) => setStep(newStep)}
                handleTrigger={handleTrigger}
                questionId={question.id}
                description={question.description}
                label={`${position} - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                options={
                  question.options
                    ? question.options.sort((a, b) =>
                        a.created_at < b.created_at ? -1 : 1
                      )
                    : []
                }
                {...register(`quizResponses.${question.id}`, {
                  required: question.required,
                })}
                isRequired={question.required}
                setValue={(value: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                }}
                selectColor={selectColor}
                defaultValue={getValues(`quizResponses.${question.id}`)}
              />
            );
          case "default":
            return (
              <MultipleChoiceRadioQuestionDefault
                type="Atendente"
                error={errors && errors[`quizResponses`] ? true : false}
                updateError={updateError}
                step={step}
                setStep={(newStep) => setStep(newStep)}
                handleTrigger={handleTrigger}
                questionId={question.id}
                description={question.description}
                label={`${position} - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                options={
                  question.options
                    ? question.options.sort((a, b) =>
                        a.created_at < b.created_at ? -1 : 1
                      )
                    : []
                }
                {...register(`quizResponses.${question.id}`, {
                  required: question.required,
                })}
                isRequired={question.required}
                setValue={(value: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                }}
                selectColor={selectColor}
                unknown={question.unknown}
                other={question.others}
                defaultValue={getValues(`quizResponses.${question.id}`)}
              />
            );
          default:
            return <></>;
        }
      case "Rating":
        return (
          <RatingRadioQuestionDefault
            error={errors && errors[`quizResponses`] ? true : false}
            updateError={updateError}
            step={step}
            setStep={(newStep) => setStep(newStep)}
            handleTrigger={handleTrigger}
            questionId={question.id}
            description={question.description}
            label={`${position} - ${question.name
              .replace(/ Empresa ]/gi, replaceQuestion)
              .replace("[", "")}`}
            {...register(`quizResponses.${question.id}`, {
              required: question.required,
            })}
            isRequired={question.required}
            setValue={(value: string, replica: string) => {
              setValue(`quizResponses.${question.id}`, value);
              setValue(`quizResponses_suggestion.${question.id}`, replica);
            }}
            has_detractor_replica={search.has_detractor_replica}
            has_neutral_replica={search.has_neutral_replica}
            has_promoter_replica={search.has_promoter_replica}
            detractor_replica={question.detractor_replica}
            neutral_replica={question.neutral_replica}
            promoter_replica={question.promoter_replica}
            selectColor={selectColor}
            defaultValue={getValues(`quizResponses.${question.id}`)}
            defaultReplica={getValues(
              `quizResponses_suggestion.${question.id}`
            )}
          />
        );
      case "Emoticon":
        return (
          <EmoticonRadioQuestion
            error={errors && errors[`quizResponses`] ? true : false}
            updateError={updateError}
            step={step}
            setStep={(newStep) => setStep(newStep)}
            handleTrigger={handleTrigger}
            questionId={question.id}
            description={question.description}
            label={`${position} - ${question.name
              .replace(/ Empresa ]/gi, replaceQuestion)
              .replace("[", "")}`}
            {...register(`quizResponses.${question.id}`, {
              required: question.required,
            })}
            isRequired={question.required}
            setValue={(value: string, replica: string) => {
              setValue(`quizResponses.${question.id}`, value);
              setValue(`quizResponses_suggestion.${question.id}`, replica);
            }}
            has_detractor_replica={search.has_detractor_replica}
            has_neutral_replica={search.has_neutral_replica}
            has_promoter_replica={search.has_promoter_replica}
            detractor_replica={question.detractor_replica}
            neutral_replica={question.neutral_replica}
            promoter_replica={question.promoter_replica}
            selectColor={selectColor}
            defaultValue={getValues(`quizResponses.${question.id}`)}
            defaultReplica={getValues(
              `quizResponses_suggestion.${question.id}`
            )}
          />
        );
      case "Like/Dislike":
        return (
          <LikeDislikeRadioQuestionDefault
            error={errors && errors[`quizResponses`] ? true : false}
            updateError={updateError}
            step={step}
            setStep={(newStep) => setStep(newStep)}
            handleTrigger={handleTrigger}
            questionId={question.id}
            selectColor={selectColor}
            description={question.description}
            label={`${position} - ${question.name
              .replace(/ Empresa ]/gi, replaceQuestion)
              .replace("[", "")}`}
            {...register(`quizResponses.${question.id}`, {
              required: question.required,
            })}
            isRequired={question.required}
            setValue={(value: string, replica: string) => {
              setValue(`quizResponses.${question.id}`, value);
              setValue(`quizResponses_suggestion.${question.id}`, replica);
            }}
            has_detractor_replica={search.has_detractor_replica}
            has_neutral_replica={search.has_neutral_replica}
            has_promoter_replica={search.has_promoter_replica}
            detractor_replica={question.detractor_replica}
            neutral_replica={question.neutral_replica}
            promoter_replica={question.promoter_replica}
            defaultValue={getValues(`quizResponses.${question.id}`)}
            defaultReplica={getValues(
              `quizResponses_suggestion.${question.id}`
            )}
          />
        );
      case "Resposta curta":
        return (
          <TextQuestionDefault
            error={errors && errors[`quizResponses`] ? true : false}
            label={`${position} - ${question.name
              .replace(/ Empresa ]/gi, replaceQuestion)
              .replace("[", "")}`}
            {...register(`quizResponses.${question.id}`, {
              required: question.required,
            })}
            selectColor={selectColor}
            setValue={(value: string) => {
              setValue(`quizResponses.${question.id}`, value);
            }}
            isRequired={question.required}
            defaultValue={getValues(`quizResponses.${question.id}`)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Flex
      as="form"
      flexDir="column"
      w="100%"
      onSubmit={handleSubmit(handle)}
      id="send-form"
    >
      <Flex justify="flex-end" color="#CBCBCB">
        <Text>
          {String(step + 1).padStart(2, "0")}/
          {String(updatedQuestions.length + 2).padStart(2, "0")}
        </Text>
      </Flex>
      <SearchProgressBar value={step + 1} max={updatedQuestions.length + 2} />
      <Tabs index={step}>
        <TabPanels>
          {search.has_nps_first && (
            <TabPanel>
              <NPSRadioQuestionDefault
                error={errors && errors[`nps`] ? true : false}
                updateError={updateError}
                step={step}
                setStep={(newStep) => setStep(newStep)}
                handleTrigger={handleTrigger}
                questionId={""}
                selectColor={selectColor}
                {...register(`nps`, {
                  required: true,
                })}
                label={`1 - ${search.nps}`}
                setValue={(nps: number) => {
                  setValue(`nps`, nps);
                }}
                isRequired={true}
                defaultValue={getValues(`nps`)}
              />
              <Flex justify="flex-end">
                <DinamicSolidButton
                  bg={company.secondary_color}
                  color="white"
                  onClick={() => handleTrigger(`nps`)}
                >
                  Próximo
                </DinamicSolidButton>
              </Flex>
            </TabPanel>
          )}

          {updatedQuestions.map((question, index) => (
            <TabPanel key={index}>
              {chooseQuestion({
                question,
                position:
                  index +
                  1 -
                  countQuestionWithAnswer +
                  (search.has_nps_first ? 2 : 0),
              })}
              <Flex
                justify={
                  index +
                    1 -
                    countQuestionWithAnswer +
                    (search.has_nps_first ? 2 : 0) ===
                  1
                    ? "flex-end"
                    : "space-between"
                }
              >
                {index +
                  1 -
                  countQuestionWithAnswer +
                  (search.has_nps_first ? 2 : 0) ===
                1 ? (
                  <></>
                ) : (
                  <DinamicOutilineButton
                    color="#5C5C5C"
                    onClick={() => {
                      setStep(step - 1);
                      clearErrors();
                    }}
                  >
                    Voltar
                  </DinamicOutilineButton>
                )}

                <DinamicSolidButton
                  bg={company.secondary_color}
                  color="white"
                  onClick={() => handleTrigger(`quizResponses.${question.id}`)}
                >
                  Próximo
                </DinamicSolidButton>
              </Flex>
            </TabPanel>
          ))}
          {!search.has_nps_first && (
            <TabPanel>
              <NPSRadioQuestionDefault
                error={errors && errors[`nps`] ? true : false}
                step={step}
                setStep={(newStep) => setStep(newStep)}
                handleTrigger={handleTrigger}
                selectColor={selectColor}
                {...register(`nps`, {
                  required: true,
                })}
                label={`${
                  updatedQuestions.length + 1 - countQuestionWithAnswer
                } - ${search.nps}`}
                setValue={(nps: number) => {
                  setValue(`nps`, nps);
                }}
                isRequired={true}
                defaultValue={getValues(`nps`)}
              />
              <Flex
                justify={
                  updatedQuestions.length === 0 ? "flex-end" : "space-between"
                }
              >
                {updatedQuestions.length === 0 ? (
                  <></>
                ) : (
                  <DinamicOutilineButton
                    color="#5C5C5C"
                    onClick={() => {
                      setStep(step - 1);
                      clearErrors();
                    }}
                  >
                    Voltar
                  </DinamicOutilineButton>
                )}
                <DinamicSolidButton
                  bg={company.secondary_color}
                  color="white"
                  onClick={() => handleTrigger(`nps`)}
                >
                  Próximo
                </DinamicSolidButton>
              </Flex>
            </TabPanel>
          )}

          <TabPanel>
            <TextQuestionDefault
              label={`${
                updatedQuestions.length + 2 - countQuestionWithAnswer
              } - ${search.discursive_question}`}
              {...register(`discursive_question`)}
              selectColor={selectColor}
              error={errors[`discursive_question`]}
              setValue={(value: string) => {
                setValue(`discursive_question`, value);
              }}
              defaultValue={getValues(`discursive_question`)}
              isRequired={false}
            />
            <Flex justify="space-between">
              <DinamicOutilineButton
                color="#5C5C5C"
                onClick={() => {
                  setStep(step - 1);
                  clearErrors();
                }}
              >
                Voltar
              </DinamicOutilineButton>
              <SubmitButton
                bg={company.secondary_color}
                color="white"
                text="Enviar"
                form="send-form"
                isLoading={formState.isSubmitting}
              />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
