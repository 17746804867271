import { useEffect, useState, useMemo } from "react";
import {
  Box as ChakraBox,
  Button,
  SimpleGrid,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { useFormFidelityProgram } from "../../hooks/formFidelityProgram";
import { api } from "../../services/api";
import { TitleDefault } from "../../components/atoms/Text/TitleDefault";
import { BackgroundCircleIcon } from "../../components/molecules/BackgroundCircleIcon";
import { FidelityProgramProgress } from "../../components/organisms/FidelityProgramProgress";
import { CloseModalButton } from "../../components/atoms/Button/CloseModalButton";
import { OpenModalLink } from "../../components/atoms/Link/OpenModalLink";
import { TenthVisitBonus } from "../../components/molecules/TenthVisitBonus";
import { FiCheckCircle } from "react-icons/fi";
import { ModalStatus } from "../../components/templates/Modal/ModalStatus";
import { GiftCouponModal } from "../../components/templates/Modal/GiftCouponModal";
import { InternalLink } from "../../components/atoms/Link/InternalLink";
import { ExternalLink } from "../../components/atoms/Link/ExternalLink";
import { FidelityProgramGiftSuccessIcon } from "../../components/atoms/Icon/FidelityProgramGiftSuccessIcon";
import { FidelityProgramProgressBar } from "../../components/atoms/Progress/FidelityProgramProgressBar";
import { useQuery } from "../../hooks/query";

interface Visit {
  created_at: Date;
  client_name: string;
  id: string;
}

export function SuccessFidelityProgram() {
  const { client_id, coupon_id } = useParams();
  const {
    company,
    fidelityProgram,
    search,
    bonuses,
    replaceQuestion,
    whiteLabel,
  } = useFormFidelityProgram();
  const [visits, setVisits] = useState<Visit[]>([]);
  const query = useQuery();
  const toast = useToast();

  const linkSearch = useMemo(() => {
    return search.has_page
      ? `/formularios/${company.slug}/${search.slug}/inicial/${client_id}`
      : `/formularios/${company.slug}/${search.slug}/${client_id}`;
  }, [client_id, company.slug, search.has_page, search.slug]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textByVisit = useMemo(() => {
    switch (visits.length) {
      case 1:
        return `Olá ${visits.length > 0 && visits[0].client_name}, seja muito
            bem vindo ao ${fidelityProgram.name}, ficamos muito felizes em
            te ver por aqui e a partir de agora você receberá benefícios e
            ofertas exclusivas do ${company.name}!`;
      case 5:
        return `Continue assim ${visits.length > 0 && visits[0].client_name}!
            Agora só faltam mais ${
              10 - visits.length
            } visitas para você ganhar o
            seu prêmio!`;
      case 9:
        return `Tá quuaase lá, ${visits.length > 0 && visits[0].client_name},
            no nosso próximo encontro você já receberá o seu prêmio! Que tal
            convidar um amigo(a) para compartilhar o presente com você, hein?`;
      default:
        return "Obrigado pela preferência";
    }
  }, [company.name, fidelityProgram.name, visits]);
  useEffect(() => {
    api.get(`/visits/${fidelityProgram.id}/${client_id}`).then((response) => {
      setVisits(response.data);
    });
  }, [client_id, company.id, fidelityProgram.id]);

  useEffect(() => {
    if (query.get("visit") === "false") {
      if (!toast.isActive("visit")) {
        toast({
          id: "visit",
          title: "Sua visita já foi computada anteriormente.",
          status: "info",
          duration: 6000,
          isClosable: true,
          position: "top",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {coupon_id ? (
        <GiftCouponModal
          company={company}
          search={search}
          nameWithArticle={replaceQuestion}
          is_falae={whiteLabel === undefined}
        />
      ) : query.get("visit") === "true" ? (
        <ModalStatus text={String(textByVisit)} icon={FiCheckCircle} />
      ) : (
        <></>
      )}
      <SimpleGrid margin="0">
        <TitleDefault title="Programa de Fidelidade" />
        <OpenModalLink
          text="Ver regras"
          onClick={onOpen}
          color={company.color}
        />
        <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Flex align="center" justify="center">
                <div
                  dangerouslySetInnerHTML={{ __html: fidelityProgram.rules }}
                />
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex margin="auto">
                <CloseModalButton
                  bg={company.color}
                  color="white"
                  onClose={onClose}
                >
                  Fechar
                </CloseModalButton>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </SimpleGrid>
      <ChakraBox>
        <FidelityProgramProgressBar value={visits.length} />
        <Flex justify="end" mt="-17px">
          <BackgroundCircleIcon bg="#FA9F42">
            <FidelityProgramGiftSuccessIcon />
          </BackgroundCircleIcon>
        </Flex>
      </ChakraBox>
      <FidelityProgramProgress
        visits={visits}
        bonuses={bonuses}
        buttonBg={company.color}
      />
      <TenthVisitBonus bonuses={bonuses} color={company.color} />
      <Flex alignItems="center" justify="center" flexDir="column">
        <InternalLink link={linkSearch}>
          <Button
            h="36px"
            w="185px"
            variant="outline"
            borderColor={company.secondary_color}
            color={company.secondary_color}
            mb="4px"
          >
            Responder Pesquisa
          </Button>
        </InternalLink>
        {search.external_link && search.name_external_link && (
          <ExternalLink link={search.external_link}>
            <Button
              h="36px"
              w="185px"
              variant="outline"
              mt="4px"
              borderColor="#0B6E4F"
              color="#0B6E4F"
            >
              {search.name_external_link}
            </Button>
          </ExternalLink>
        )}
      </Flex>
    </>
  );
}
