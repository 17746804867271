
import { SpinWhellButton } from "./spinWeelButton";
import { SpinWheelBody } from "./spinWheelBody";
import { SpinWheelPointer } from "./spinWheelPointer";
import { SpinWheelResult } from "./spinWheelResult";
import { SpinWheelRoot } from "./spinWheelRoot";
import { SpinWheelSegment } from "./spinWheelSegment";
import { SpinWheelSegmentContent } from "./spinWheelSegmentContent";
import { SpinWheelContent } from "./spinWhellContent";

export const SpinWheel = {
    Root: SpinWheelRoot,
    Content: SpinWheelContent,
    Body: SpinWheelBody,
    Button: SpinWhellButton,
    Segment: SpinWheelSegment,
    SegmentContent: SpinWheelSegmentContent,
    Pointer: SpinWheelPointer,
    Result: SpinWheelResult,

}