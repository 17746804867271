import { Text } from "@chakra-ui/react";

interface VisitTextProps {
  text: string;
}

export function VisitText({ text }: VisitTextProps) {
  return (
    <Text
      fontSize="16px"
      lineHeight="18px"
      fontWeight="bold"
      color="#888888"
      mb="10px"
      textAlign="center"
    >
      {text}
    </Text>
  );
}
