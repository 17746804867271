import { useEffect, useMemo, useState } from "react";

import { Box } from "@chakra-ui/react";

import { useFormClient } from "../../../hooks/formClient";
import { useNavigate, useParams } from "react-router-dom";
import { SubtitleDefault } from "../../../components/atoms/Text/SubtitleDefault";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { SearchButtonList } from "../../../components/organisms/SearchButtonList";
import { Loader } from "../../../components/templates/Loader";
import { api } from "../../../services/api";

interface SearchLinks {
  name: string;
  text_color: string;
  button_color: string;
  link: string;
}

export function IndexLinktreeControl() {
  const navigate = useNavigate();
  const { search, company, page, questionQuery, client, whiteLabel } =
    useFormClient();
  const { slug, search_slug } = useParams();
  const [searchLinks, setSearchLinks] = useState<SearchLinks[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const linkSearch = useMemo(() => {
    if (search.reverse_order && !client) {
      if (search.unique_answer_client && search.anonymous_search) {
        return `/formularios/${slug}/${search_slug}/cliente/cadastro`;
      } else {
        return `/formularios/${slug}/${search_slug}/cliente/inicial`;
      }
    } else {
      if (search.has_page) {
        if (client) {
          if (page) {
            return `/formularios/${slug}/${search_slug}/${page}/${client}?${questionQuery}`;
          } else {
            return `/formularios/${slug}/${search_slug}/pagina/inicial/${client}?${questionQuery}`;
          }
        } else {
          if (page) {
            return `/formularios/${slug}/${search_slug}/${page}?${questionQuery}`;
          } else {
            return `/formularios/${slug}/${search_slug}/pagina/inicial?${questionQuery}`;
          }
        }
      } else {
        if (search.id === "9bcde58d-5c8d-495c-8234-7705a242b2d2") {
          return `/formularios/${slug}/${search_slug}/brigaderia`;
        } else {
          if (client) {
            return `/formularios/${slug}/${search_slug}/${client}?${questionQuery}`;
          } else {
            return `/formularios/${slug}/${search_slug}?${questionQuery}`;
          }
        }
      }
    }
  }, [
    client,
    page,
    questionQuery,
    search.anonymous_search,
    search.has_page,
    search.id,
    search.reverse_order,
    search.unique_answer_client,
    search_slug,
    slug,
  ]);

  useEffect(() => {
    if (search.id !== undefined && !search.active) {
      navigate(`/formularios/${company.slug}/${search.slug}/desativada`);
    }

    api.get(`/clientForms/linktree/${search.id}`).then((response) => {
      setSearchLinks(response.data);
      setIsLoading(true);
    });
  }, [company.slug, navigate, search.active, search.id, search.slug]);
  const user = localStorage.getItem(`@B2S-${company.slug}:user`);

  return (
    <Loader isLoading={isLoading} whiteLabel={whiteLabel}>
      {user && search.has_transparency ? (
        <>
          <SubtitleDefault subtitle={`Olá, ${String(JSON.parse(user).name)}`} />
          <TitleDefault title="Para onde deseja ir?" />
        </>
      ) : (
        <>
          <TitleDefault title="Para onde deseja ir?" />
        </>
      )}
      <Box my="10">
        <SearchButtonList
          searchLink={linkSearch}
          primaryColor={company.color ?? "#ffffff"}
          secondaryColor={company.secondary_color ?? "#ffffff"}
          textColor="white"
          external_link={search.external_link}
          name_external_link={search.name_external_link}
          anotherLinks={searchLinks}
        />
      </Box>
    </Loader>
  );
}
