import { ElementType, useEffect, useState } from "react";
import { ModalStatusBody } from "../../../organisms/Modal/ModalStatusBody";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";

interface ModalStatusProps {
  icon: ElementType;
  text: string;
}

export function ModalStatus({ icon, text }: ModalStatusProps) {
  const [onOpen, setOnOpen] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setOnOpen(false);
    }, 3000);
  }, []);
  return (
    <Modal
      isOpen={onOpen}
      onClose={() => setOnOpen(false)}
      size="xs"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalStatusBody icon={icon} text={text} />
      </ModalContent>
    </Modal>
  );
}
