import { Box, Flex, Input, InputProps } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./custom-phone-input.css";
import ptBr from "react-phone-number-input/locale/pt-BR.json";
import { countryCodeValidation } from "../../../../utils/countryValidation";
interface PhoneInputProps extends InputProps {
  name: string;
  id: string;
  isRequired?: boolean;
  default_country?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, PhoneInputProps> = (
  { name, id, isRequired = false, default_country, ...rest },
  ref
) => {
  const [value, setValue] = useState<string | undefined>();

  const handlePhoneChange = (newValue: string | undefined) => {
    setValue(newValue);
    const event = {
      target: {
        name,
        value: newValue,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <Flex
      direction="row"
      align="center"
      width="100%"
      height="32px"
      justify="center"
    >
      <Box px={4} w="100%">
        <PhoneInputWithCountrySelect
          labels={ptBr}
          limitMaxLength={true}
          addInternationalOption={false}
          international={true}
          countryCallingCodeEditable={false}
          onCountryChange={() => setValue("")}
          defaultCountry={countryCodeValidation(default_country ?? "")}
          value={value}
          onChange={handlePhoneChange}
        />
      </Box>
      <Input
        w="0"
        ml="10px"
        ref={ref}
        name={name}
        color="transparent"
        id={id}
        value={value}
        isRequired={isRequired}
        {...rest}
      />
    </Flex>
  );
};

export const PhoneInput = forwardRef(InputBase);
