import { FaRegSmile, FaSmile } from "react-icons/fa";

interface SmileIconProps {
  selected: number;
}

export function SmileIcon({ selected }: SmileIconProps) {
  return selected === 4 ? (
    <FaSmile color="#8fd460" size={40} />
  ) : (
    <FaRegSmile color="#8fd460" size={40} />
  );
}
