import { ElementType } from "react";
import { Icon } from "@chakra-ui/react";

interface ModalStatusIconProps {
  icon: ElementType;
  color: string;
}

export function ModalStatusIcon({ icon, color }: ModalStatusIconProps) {
  return <Icon as={icon} color={color} fontSize="59px" mt="10px" />;
}
