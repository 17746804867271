import { Text } from "@chakra-ui/react";

interface FormErrorMessageDefaultProps {
  error?: string;
}

export function FormErrorMessageDefault({
  error,
}: FormErrorMessageDefaultProps) {
  return <Text color="#d32f2f">{error}</Text>;
}
