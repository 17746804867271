import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SpinWheelContentProps {
    children: ReactNode;
}

export function SpinWheelContent({ children }: SpinWheelContentProps) {
    return (
        <Flex
            borderRadius="100%"
            position="relative"
            width={["250px", "300px", "400px", "500px"]}
            height={["250px", "300px", "400px", "500px"]}
            maxWidth="100%"
            maxHeight="100%"
        >
            {children}
        </Flex>
    );
}
