import { Box, Flex, Text } from "@chakra-ui/react";
import { QRCodeCanvas } from "qrcode.react";
import { OutlineListButton } from "../../atoms/Button/OutlineListButton";
import { InternalLink } from "../../atoms/Link/InternalLink";

interface QrCodeProps {
  link: any;
  thanksLink: string;
  anonymousSearch: boolean;
  primaryColor: string;
}
export function QRcode({
  link,
  anonymousSearch,
  primaryColor,
  thanksLink,
}: QrCodeProps) {
  return (
    <Flex flexDir="column" justify="center" align="center">
      <Text>
        <b>Escaneie e se cadastre</b> pelo seu celular
      </Text>
      <Box my={2}>
        <QRCodeCanvas
          id={`qrCode`}
          value={link}
          level="L"
          about="QRCode B2S"
          includeMargin={true}
        />
      </Box>
      {!anonymousSearch && (
        <InternalLink link={thanksLink}>
          <OutlineListButton text="Não se identificar" color={primaryColor} />
        </InternalLink>
      )}
    </Flex>
  );
}
