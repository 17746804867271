import { useDisclosure, Modal, ModalContent, Flex } from "@chakra-ui/react";
import { OpenModalLink } from "../../../atoms/Link/OpenModalLink";
import { ModalGiftListTextSubtitleProps } from "../../../molecules/Modal/ModalGiftListTextSubtitle";
import { ModalGiftListBody } from "../../../organisms/Modal/ModalGiftListBody";
interface ModalGiftListProps {
  color: string;
  buttonBg: string;
  buttonTextColor: string;
  giftList: ModalGiftListTextSubtitleProps[];
}

export function ModalGiftList({
  buttonBg,
  buttonTextColor,
  color,
  giftList,
}: ModalGiftListProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex justify="center" my={2}>
      <OpenModalLink color={color} onClick={onOpen} text="Ver prêmios" />
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
        <ModalContent>
          <ModalGiftListBody
            onClose={onClose}
            buttonBg={buttonBg}
            buttonTextColor={buttonTextColor}
            giftColor="#FA9F42"
            giftList={giftList}
          />
        </ModalContent>
      </Modal>
    </Flex>
  );
}
