import { Button } from "@chakra-ui/react";
import { shade } from "polished";
interface SubmitButtonProps {
  color?: string;
  bg: string;
  text: string;
  form: string;
  isLoading?: boolean;
}
export function SubmitButton({
  text,
  bg,
  color = "white",
  form,
  isLoading,
}: SubmitButtonProps) {
  return (
    <Button
      type="submit"
      form={form}
      color={color}
      bg={bg === undefined ? "#2b88a1" : bg}
      _hover={{
        backgroundColor: shade(0.2, bg === undefined ? "#2b88a1" : bg),
      }}
      _active={{
        backgroundColor: shade(0.2, bg === undefined ? "#2b88a1" : bg),
      }}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
}
