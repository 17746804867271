import { Checkbox } from "../../../atoms/Checkbox";
import { OptionDivider } from "../../../atoms/Divider/OptionsDivider";

interface IProps {
  setSelectOther: (select: boolean) => void;
  selectColor: string;
  defaultChecked?: boolean;
}
export function CheckBoxGroupOther({
  setSelectOther,
  selectColor,
  defaultChecked,
}: IProps) {
  return (
    <>
      <Checkbox
        value="Outros"
        label="Outros"
        setValue={(event) => {
          setSelectOther(event.target.checked);
        }}
        color={selectColor}
        defaultChecked={defaultChecked}
      />
      <OptionDivider color={selectColor} />
    </>
  );
}
