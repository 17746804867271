import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SpinWheelBodyProps {
    rotation: number;
    children: ReactNode;
}

export function SpinWheelBody({ rotation, children }: SpinWheelBodyProps) {

    return (
        <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            background="#fff"
            borderRadius="100%"
            boxShadow="0 3px 40px rgba(0, 0, 0, 0.5)"
            transition="transform 5s ease-in-out"
            style={{ transform: `rotate(${rotation}deg)` }}
            overflow="hidden"
        >
            {children}
        </Box>
    );
};
