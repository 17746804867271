import { Flex, SimpleGrid } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { MultipleChoiceRadioQuestionDefault } from "../../../components/organisms/RadioQuestion/MultipleChoiceRadioQuestion/MultipleChoiceRadioQuestionDefault";
import { ReactSelectQuestion } from "../../../components/organisms/SelectQuestion/ReactSelectQuestion";
import { Loader } from "../../../components/templates/Loader";
import { useFormClient } from "../../../hooks/formClient";

interface UnifyQrcode {
  id: string;
  franchise: {
    name: string;
  };
}

interface FranchiseGroup {
  radical?: string;
}

interface UnifyQrcodeFranchiseGroup {
  question: string;
  form_message: string;
  list_view: string;
}
interface IProps {
  unifyQrcodes: UnifyQrcode[];
  unifyQrcodeFranchiseGroup: UnifyQrcodeFranchiseGroup;
  franchiseGroup: FranchiseGroup;
}

export function FranchisesGroup({
  unifyQrcodes,
  unifyQrcodeFranchiseGroup,
  franchiseGroup,
}: IProps) {
  const navigate = useNavigate();
  const { company, search, whiteLabel } = useFormClient();
  const [selectPage, setSelectPage] = useState("");

  const handleSubmitFranchise = useCallback(async () => {
    navigate(
      `/formularios/${company.slug}/${search.slug}/unidades/${selectPage}`
    );
  }, [company.slug, navigate, search.slug, selectPage]);
  return (
    <Loader
      isLoading={unifyQrcodes && unifyQrcodes.length !== 0}
      whiteLabel={whiteLabel}
    >
      <HTMLText html={unifyQrcodeFranchiseGroup.form_message} />
      <Flex as="form" direction="column" onSubmit={handleSubmitFranchise}>
        <SimpleGrid spacingY={4} columns={1} mt="3">
          {unifyQrcodeFranchiseGroup.list_view === "checkbox" ? (
            <MultipleChoiceRadioQuestionDefault
              label={unifyQrcodeFranchiseGroup.question}
              name="unify_qrcode_id"
              options={unifyQrcodes
                .sort((a, b) => (a.franchise.name > b.franchise.name ? 1 : -1))
                .map((unifyQrcode) => ({
                  name: franchiseGroup.radical
                    ? unifyQrcode.franchise.name.replace(
                        franchiseGroup.radical,
                        ""
                      )
                    : unifyQrcode.franchise.name,
                  value: unifyQrcode.id,
                }))}
              setValue={(value: string) => {
                setSelectPage(value);
              }}
              isRequired
              selectColor={company.color}
            />
          ) : (
            <ReactSelectQuestion
              label={unifyQrcodeFranchiseGroup.question}
              name="unify_qrcode_id"
              options={unifyQrcodes
                .sort((a, b) => (a.franchise.name > b.franchise.name ? 1 : -1))
                .map((unifyQrcode) => ({
                  name: franchiseGroup.radical
                    ? unifyQrcode.franchise.name.replace(
                        franchiseGroup.radical,
                        ""
                      )
                    : unifyQrcode.franchise.name,
                  value: unifyQrcode.id,
                }))}
              setValue={(value: string) => {
                setSelectPage(value);
              }}
              isRequired
            />
          )}
        </SimpleGrid>
        <Flex justify="flex-end">
          <Button
            type="submit"
            form="send-form"
            onClick={handleSubmitFranchise}
            fontWeight="normal"
            color="#fff"
            bg={company.color}
            _hover={{
              bg: company.color,
              filter: "brightness(0.8)",
            }}
            maxW="40"
            disabled={selectPage !== "" ? false : true}
          >
            Avançar
          </Button>
        </Flex>
      </Flex>
    </Loader>
  );
}
