import {
  Flex,
  SimpleGrid,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { Loader } from "../../../components/templates/Loader";
import { QuestionSearchOnePage } from "../../../components/templates/QuestionSearchOnePage";
import { useFormClient } from "../../../hooks/formClient";

interface Question {
  id: string;
  name: string;
  nick: string;
  required: boolean;
  others?: boolean;
  unknown?: boolean;
  description: string;
  type_csat?: "numerico" | "satisfeito";
  type:
  | "NPS"
  | "CSAT"
  | "Caixa de seleção"
  | "Multipla escolha"
  | "Rating"
  | "Like/Dislike"
  | "Resposta curta";
  options?: {
    id: string;
    name: string;
    created_at: Date;
  }[];
  detractor_replica?: string;
  neutral_replica?: string;
  promoter_replica?: string;
  format: "default" | "select";
}
interface IProps {
  handle: (data: any) => void;
  questions: Question[];
  loading: boolean;
  openModal?: boolean;
}
export function SearchOnePage({
  handle,
  questions,
  loading,
  openModal = false,
}: IProps) {
  const { search, company, whiteLabel } = useFormClient();
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Loader isLoading={loading} whiteLabel={whiteLabel}>
      <Modal isOpen={openModal} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Inatividade</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Flex margin="auto">
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Não
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  if (search.reverse_order) {
                    navigate(-1);
                  } else {
                    navigate(0);
                  }
                }}
              >
                Sim
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {!search.has_page &&
        search.unifyQrcodesSearches &&
        search.unifyQrcodesSearches.length === 0 && (
          <HTMLText html={search.form_message} />
        )}

      <SimpleGrid spacingY={4} columns={1} mt="3">
        <QuestionSearchOnePage
          questions={questions}
          selectColor={company.color}
          handle={handle}
        />
      </SimpleGrid>
    </Loader>
  );
}
