import { Input, InputProps } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
interface PhoneInputProps extends InputProps {
  name: string;
  id: string;
  isRequired?: boolean;
}
const InputBase: ForwardRefRenderFunction<HTMLInputElement, PhoneInputProps> = (
  { name, id, isRequired = false, ...rest },
  ref
) => {
  return (
    <Input
      w="calc(100% - 15px)"
      ml="10px"
      type="email"
      ref={ref}
      name={name}
      id={id}
      isRequired={isRequired}
      {...rest}
      onChange={(e: any) => {
        var value = e.target.value;
        value = value.replace(" ", "");
        e.target.value = value;
        if (rest.onChange) {
          rest.onChange(e);
        }
      }}
    />
  );
};

export const EmailInput = forwardRef(InputBase);
