import { FieldError, UseFormRegister, FieldValues } from "react-hook-form";
import { useState } from "react";
import { FormLabelLegend } from "../../atoms/FormLabel/FormLabelLegend";
import { UnstyledInput } from "../../atoms/Input/UnstyledInput";
import { FlexCenter } from "../../atoms/Flex/FlexCenter";
import { FormControlFieldset } from "../../atoms/FormControl/FormControlFieldset";
import { FormErrorMessageDefault } from "../../atoms/FormErrorMessage/FormErrorMessageDefault";

interface DefaultFormControlMaterialUIProps {
  isRequired?: boolean;
  error?: FieldError;
  name: string;
  text: string;
  bg?: string;
  register: UseFormRegister<FieldValues>;
  is_totem?: boolean;
}

export function DefaultFormControlMaterialUI({
  text,
  name,
  error,
  register,
  bg = "rgba(0, 0, 0, 0.12)",
  isRequired,
  is_totem,
}: DefaultFormControlMaterialUIProps) {
  const [focus, setFocus] = useState(false);
  return (
    <>
      <FormControlFieldset
        isRequired={isRequired}
        focus={focus}
        bg={bg}
        error={!!error}
        id={name}
      >
        <FormLabelLegend
          text={text}
          htmlFor={name}
          color={focus ? bg : undefined}
        />
        <FlexCenter>
          <UnstyledInput
            autoComplete={is_totem ? "off" : "on"}
            {...register(name)}
            id={name}
            isRequired={isRequired}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
            }}
          />
        </FlexCenter>
      </FormControlFieldset>
      {!!error && <FormErrorMessageDefault error={error.message} />}
    </>
  );
}
