import { Text } from "@chakra-ui/react";

interface ReactProps {
  description: string;
}
export function QuestionSearchDescription({ description }: ReactProps) {
  return (
    <Text fontWeight="400" fontSize="sm" color="gray.500">
      {description}
    </Text>
  );
}
