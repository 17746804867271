import { Text } from "@chakra-ui/react";
interface TitleDefaultProps {
  title: string;
}

export function TitleDefault({ title }: TitleDefaultProps) {
  return (
    <Text
      fontWeight="700"
      fontSize="22px"
      lineHeight="26.4px"
      textAlign="center"
    >
      {title}
    </Text>
  );
}
