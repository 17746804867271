export function formatDateBr(date: Date | string) {
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(date.toString().replace("Z", "-03:00")));
}

export function formatDateDayAndMonth(date: Date) {
  return new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
  }).format(new Date(date.toString().replace("Z", "-03:00")));
}
