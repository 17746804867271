import { useFormClient } from "../../../hooks/formClient";

import * as yup from "yup";
import { useNavigate, useParams } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useState, useEffect } from "react";
import { api } from "../../../services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@chakra-ui/react";
import { StoreClientOnePage } from "../SearchOnePage/storeClient";
import { phoneNumberValidation } from "../../../utils/phoneValidation";

type CreateClientFormData = {
  surname: string;
  name: string;
  email: string;
  phone: string;
  born_date?: string;
  company_id: string;
  approved?: boolean;
  fidelity?: boolean;
  cpf?: string;
  cnpj?: string;
  unique: boolean;
  company_name?: string;
};

const createClientFormData = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  surname: yup
    .string()
    .required("Sobrenome obrigatório")
    .min(3, "O sobrenome deve conter no mínimo 3 caracteres"),
  email: yup
    .string()
    .required("O E-mail é obrigatório")
    .email("Formato inválido")
    .min(6, "Número de caracteres muito pequeno"),
  phone: yup
    .string()
    .required("O Whatsapp é obrigatório")
    .test(
      "is-valid-phone",
      "Número de telefone inválido",
      phoneNumberValidation
    ),
  approved: yup.boolean().nullable(),
  fidelity: yup.boolean().nullable(),
  cpf: yup
    .string()
    .nullable()
    .matches(
      /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2})|([0-9]{11}))$/,
      "Formato inválido"
    )
    .test("validCpf", "CPF inválido", (cpf) => {
      if (cpf) {
        var value = cpf.replaceAll(".", "").replace("-", "");
        var sum;
        var rest;
        sum = 0;
        if (value.split("").every((num, i, arr) => num === arr[0])) {
          return false;
        }
        for (let i = 1; i <= 9; i++) {
          sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
        }
        rest = (sum * 10) % 11;

        if (rest === 10 || rest === 11) {
          rest = 0;
        }
        if (rest !== parseInt(value.substring(9, 10))) {
          return false;
        }

        sum = 0;
        for (let i = 1; i <= 10; i++) {
          sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
        }
        rest = (sum * 10) % 11;

        if (rest === 10 || rest === 11) {
          rest = 0;
        }
        if (rest !== parseInt(value.substring(10, 11))) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }),
  cnpj: yup.string().nullable(),
  company_name: yup.string().nullable(),
  born_date: yup
    .string()
    .nullable()
    .matches(
      /(0?[1-9]|[12]\d|30|31)\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-1][0-9])/,
      "Formato inválido "
    ),
  unique: yup.boolean(),
});

export function IndexStoreClientControl() {
  const { search, company, page, questionQuery, isTest } = useFormClient();
  const navigate = useNavigate();
  const { slug, search_slug, answer_id } = useParams();
  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(createClientFormData),
  });
  const [responseChannel, setResponseChannel] = useState<string>("");
  const toast = useToast();

  useEffect(() => {
    const response = localStorage.getItem(`@B2S:link`);
    if (response) {
      setResponseChannel("qrcode");
    } else {
      setResponseChannel("link");
    }
  }, [responseChannel]);

  const handleCreateClient: SubmitHandler<CreateClientFormData> = useCallback(
    async (formValue) => {
      try {
        if (!isTest) {
          if (answer_id) {
            const response = await api.post("/clientForms", {
              name: formValue.name.trim() + " " + formValue.surname.trim(),
              cpf: formValue.cpf,
              ...(search.has_cnpj ? { cnpj: formValue.cnpj } : ""),
              ...(search.has_cnpj
                ? { company_name: formValue.company_name }
                : ""),

              email: formValue.email.trim(),
              phone: formValue.phone,
              born_date: formValue.born_date,
              answer_id: answer_id,
              company_id: search.company_id,
              approved: formValue.approved,
              fidelity: formValue.fidelity,
              search_id: search.id,
            });

            localStorage.setItem(
              `@B2S-${company.slug}:user`,
              JSON.stringify({
                name: response.data.name,
                email: response.data.email,
                id: response.data.id,
              })
            );
            if (response.data.coupons) {
              navigate(
                `/formularios/${slug}/${search_slug}/obrigado/${response.data.coupons[0].id}`
              );
            } else {
              navigate(`/formularios/${slug}/${search_slug}/obrigado`);
            }
          } else {
            const response = await api.post("/clientForms", {
              name: formValue.name + " " + formValue.surname,
              cpf: formValue.cpf,
              ...(search.has_cnpj ? { cnpj: formValue.cnpj } : ""),
              ...(search.has_cnpj
                ? { company_name: formValue.company_name }
                : ""),
              email: formValue.email,
              phone: formValue.phone,
              born_date: formValue.born_date,
              company_id: search.company_id,
              approved: formValue.approved,
              unique: search.unique_answer_client,
              search_id: search.id,
              fidelity: formValue.fidelity,
              is_partitioned: search.is_partitioned,
            });
            if (search.has_transparency) {
              localStorage.setItem(
                `@B2S-${company.slug}:user`,
                JSON.stringify({
                  name: response.data.name,
                  email: response.data.email,
                  id: response.data.id,
                })
              );
            }
            if (search.is_partitioned) {
              navigate(`/formularios/${slug}/${search_slug}/obrigado`);
            } else {
              if (search.has_page) {
                if (page) {
                  navigate(
                    `/formularios/${slug}/${search_slug}/${page}/${response.data.id}?${questionQuery}`
                  );
                } else {
                  navigate(
                    `/formularios/${slug}/${search_slug}/pagina/inicial/${response.data.id}`
                  );
                }
              } else {
                navigate(
                  `/formularios/${slug}/${search_slug}/${response.data.id}?${questionQuery}`
                );
              }
            }
          }
        }
      } catch (err: any) {
        if (err.response && err.response.data) {
          const { data } = err.response;

          if (
            data.validation &&
            data.validation.body &&
            data.validation.body.keys.includes("email")
          ) {
            form.setError(
              "email",
              { message: "Formato inválido" },
              { shouldFocus: true }
            );
          } else if (data.message === "Client already exists") {
            toast({
              title: `Sua resposta já foi realizada!`,
              position: "top",
              isClosable: true,
              duration: 5000,
              status: "error",
            });
          } else {
            toast({
              title: `Ocorreu um erro. Por favor, tente novamente mais tarde.`,
              position: "top",
              isClosable: true,
              duration: 5000,
              status: "error",
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isTest,
      answer_id,
      search.has_cnpj,
      search.company_id,
      search.id,
      search.unique_answer_client,
      search.is_partitioned,
      search.has_transparency,
      search.has_page,
      company.slug,
      navigate,
      slug,
      search_slug,
      page,
      questionQuery,
      toast,
    ]
  );

  switch (search.type_form) {
    case "onepage":
      return <StoreClientOnePage handle={handleCreateClient} form={form} />;
    case "multipages":
      return <StoreClientOnePage handle={handleCreateClient} form={form} />;

    default:
      return <StoreClientOnePage handle={handleCreateClient} form={form} />;
  }
}
