import { useFormClient } from "../../../hooks/formClient";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { api } from "../../../services/api";
import { SearchUnits } from "../SearchOnePage/units";
import { useNavigate } from "react-router-dom";

interface Search {
  id: string;
  has_page: boolean;
  reverse_order: boolean;
  anonymous_search: boolean;
  has_linktree?: boolean;
  unique_answer_client: boolean;
  active: boolean;
  slug: string;
  company_name: string;
  company_slug: string;
  is_totem: boolean;
  min_to_next_answer: number;
}

interface UnifyQrcode {
  question_unity: string;
  form_message: string;
  list_view: string;
}

interface Franchise {
  radical: string;
}

interface IResponse {
  searches: Search[];
  franchise: Franchise;
  unifyQrcode: UnifyQrcode;
}
export function IndexLoaderUnits() {
  const navigate = useNavigate();
  const { search } = useFormClient();
  const { unify_qrcode_id } = useParams();
  const [searches, setSearches] = useState<Search[]>([]);
  const [franchise, setFranchise] = useState<Franchise>({} as Franchise);
  const [unifyQrcode, setUnifyQrcode] = useState<UnifyQrcode>(
    {} as UnifyQrcode
  );

  useEffect(() => {
    api
      .get<IResponse>(`clientForms/units/${unify_qrcode_id}`)
      .then((response) => {
        setSearches(response.data.searches.filter((search) => search.active));
        setUnifyQrcode(response.data.unifyQrcode);
        setFranchise(response.data.franchise);
      });
  }, [unify_qrcode_id]);

  useEffect(() => {
    searches.forEach((search) => {
      const checkIfClientAnswers = localStorage.getItem(`@falae:${search.id}`);

      if (checkIfClientAnswers && !search.is_totem) {
        const today = new Date().getTime();

        const diffInMs = Math.abs(today - Number(checkIfClientAnswers));

        const diffInMin = diffInMs / 60000;

        if (diffInMin < search.min_to_next_answer) {
          window.location.href = `${process.env.REACT_APP_WEB_URL}/formularios/${search.company_slug}/${search.slug}/obrigado`;
        }
      }
    });
  }, [navigate, searches]);

  switch (search.type_form) {
    case "onepage":
      return (
        <SearchUnits
          searches={searches}
          unifyQrcode={unifyQrcode}
          radical={franchise.radical}
        />
      );

    case "multipages":
      return (
        <SearchUnits
          searches={searches}
          unifyQrcode={unifyQrcode}
          radical={franchise.radical}
        />
      );

    default:
      return <></>;
  }
}
