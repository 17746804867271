import { InputProps } from "@chakra-ui/react";
import { FormControlFieldset } from "../../../atoms/FormControl/FormControlFieldset";
import { FormLabelLegend } from "../../../atoms/FormLabel/FormLabelLegend";
import { UnstyledInput } from "../../../atoms/Input/UnstyledInput";
import { FormControlQuestionSearch } from "../../../molecules/FormControl/FormControlQuestionSearch";
import { useEffect, useState } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { FormErrorMessageDefault } from "../../../atoms/FormErrorMessage/FormErrorMessageDefault";

interface IProps extends InputProps {
  label: string;
  name: string;
  description?: string;
  setValue: (value: string) => void;
  selectColor: string;
  error?:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | boolean
    | undefined;
  defaultValue?: string;
  isRequired: boolean;
}

export function TextQuestionDefault({
  label,
  name,
  selectColor,
  setValue,
  defaultValue,
  error,
  isRequired,
  description,
}: IProps) {
  const [inputValue, setInputValue] = useState<string>(defaultValue ?? "");
  const [hasError, setHasError] = useState<boolean>();
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setHasError(!!error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <FormControlQuestionSearch
        label={label}
        name={`form-${name}`}
        isRequired={isRequired}
        description={description}
      >
        {hasError && <FormErrorMessageDefault error="Questão obrigatória" />}
        <FormControlFieldset
          bg={selectColor}
          id="ter"
          error={!!hasError}
          focus={focus}
        >
          <FormLabelLegend
            htmlFor="promotor"
            text="Digite sua resposta"
            color={selectColor}
            focus={focus}
          />
          <UnstyledInput
            id={`input-${name}`}
            value={inputValue}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            isRequired={isRequired}
            name={name}
            onChange={(e: any) => {
              const value = e.target.value;
              setInputValue(value);
              setValue(value);
            }}
          />
        </FormControlFieldset>
      </FormControlQuestionSearch>
    </>
  );
}
