import { Text } from "@chakra-ui/react";

interface DinamicTextLinkProps {
  text: string;
  onClick: () => void;
}

export function DinamicClickText({ text, onClick }: DinamicTextLinkProps) {
  return (
    <Text
      onClick={onClick}
      _hover={{ cursor: "pointer", textDecoration: "underline" }}
    >
      {text}
    </Text>
  );
}
