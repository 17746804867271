import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { SearchTextLink } from "../../atoms/Link/SearchTextLink";

interface SearchSimpleLinkProps {
  link: string;
  text: string;
}

export function SearchSimpleLink({ link, text }: SearchSimpleLinkProps) {
  return (
    <ChakraLink as={Link} to={link}>
      <SearchTextLink text={text} />
    </ChakraLink>
  );
}
