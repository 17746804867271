import { useMemo } from "react";
import { Text, Box } from "@chakra-ui/react";
interface Bonus {
  name: string;
  total_visits: number;
}
interface TenthVisitBonusProps {
  bonuses: Bonus[];
  color: string;
}
export function TenthVisitBonus({ bonuses, color }: TenthVisitBonusProps) {
  const bonus = useMemo(() => {
    return bonuses.find((bonus) => bonus.total_visits === 10);
  }, [bonuses]);

  return (
    <Box>
      {bonus && (
        <>
          <Text fontSize="14px" lineHeight="18px" textAlign="center">
            O que você irá ganhar ao completar a {bonus.total_visits}ª visita
          </Text>
          <Text
            fontSize="16px"
            lineHeight="18px"
            fontWeight="bold"
            textAlign="center"
            color={color}
          >
            {bonus.name}
          </Text>
        </>
      )}
    </Box>
  );
}
