import { RadioProps } from "@chakra-ui/react";

import ReactSelect from "react-select";
import { FormControlQuestionSearch } from "../../../molecules/FormControl/FormControlQuestionSearch";

interface IProps extends RadioProps {
  options: {
    name: string;
    value: string;
  }[];
  label: string;
  name: string;
  setValue: (value: string) => void;
}

export function ReactSelectQuestion({
  options,
  label,
  name,
  setValue,
}: IProps) {
  return (
    <FormControlQuestionSearch isRequired label={label} name={name}>
      <ReactSelect
        placeholder="Selecione uma unidade"
        classNamePrefix="select"
        isSearchable={true}
        isClearable={true}
        onChange={(e: any) => {
          if (e) {
            setValue(e.value);
          }
        }}
        name={name}
        options={options.map((option) => {
          return {
            value: option.value,
            label: option.name,
          };
        })}
      />
    </FormControlQuestionSearch>
  );
}
