import { SearchColorText } from "../../../components/atoms/SearchColorText";

export function AnsweredSearchOnePage() {
  return (
    <SearchColorText
      text="A pesquisa de satisfação já foi respondida"
      color="#000"
    />
  );
}
