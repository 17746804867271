import { Text } from "@chakra-ui/react";
interface SubtitleDefaultProps {
  subtitle: string;
}

export function SubtitleDefault({ subtitle }: SubtitleDefaultProps) {
  return (
    <Text color="gray.400" fontSize="14px" lineHeight="18px" textAlign="center">
      {subtitle}
    </Text>
  );
}
