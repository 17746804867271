import {
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { formatDateBr } from "../../../../utils/formatDate";

interface GiftAccodionDescriptionProps {
  coupon: {
    expiration_date: Date;
    client: {
      name: string;
    };
    bonus: {
      name: string;
    };
  };
  nameWithArticle: string;
  search: {
    coupon_message: string;
  };
}

export function GiftAccodionDescription({
  coupon,
  nameWithArticle,
  search,
}: GiftAccodionDescriptionProps) {
  return (
    <Accordion defaultIndex={[0]} allowMultiple border="transparent">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box
              flex="1"
              fontSize="22px"
              fontWeight="bold"
              textAlign="center"
              mb="4"
            >
              Informações
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Text as="h2" fontSize="20px" mb="6px" fontWeight="regular">
            Parabéns {coupon.client.name},
          </Text>
          <Text mb="5px">
            Você acaba de ganhar {coupon.bonus?.name} no seu próximo encontro
            com {nameWithArticle}
          </Text>
          <div dangerouslySetInnerHTML={{ __html: search.coupon_message }} />

          <Text mb="5px">
            <b>Validade: {formatDateBr(coupon.expiration_date)}</b>
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
