import { Input, InputProps } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
interface UnstyledInputProps extends InputProps {
  name: string;
  id: string;
  isRequired?: boolean;
}
const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  UnstyledInputProps
> = ({ name, id, isRequired = false, ...rest }, ref) => {
  return (
    <Input
      autoComplete="off"
      variant="unstyled"
      w="calc(100% - 15px)"
      ml="10px"
      ref={ref}
      name={name}
      id={id}
      isRequired={isRequired}
      {...rest}
    />
  );
};

export const UnstyledInput = forwardRef(InputBase);
