import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_FORMULARIO_URL,
});

const apiSystem = axios.create({
  baseURL: process.env.REACT_APP_API_SISTEMA_URL,
});

api.interceptors.response.use(
  function successRequest(response) {
    return response;
  },
  async function errorRequest(error) {
    if (error.message === "Network Error") {
      apiSystem.post(`/clientForms/form/warning`, {
        url: error.config.url,
      });
    }
    try {
      const originalRequest = error.config;

      const res = await apiSystem({
        ...originalRequest,
        baseURL: process.env.REACT_APP_API_SISTEMA_URL,
      });

      return res;
    } catch {
      return Promise.reject(error);
    }
  }
);
export { api };
