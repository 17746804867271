import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FormProps {
  id: string;
  onSubmit: any;
  children: ReactNode;
}

export function Form({ id, onSubmit, children }: FormProps) {
  return (
    <Flex as="form" flexDir="column" w="100%" id={id} onSubmit={onSubmit}>
      {children}
    </Flex>
  );
}
