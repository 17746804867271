import { Text } from "@chakra-ui/react";

interface ClientConfirmatioTitleProps {
  name: string;
}

export function ClientConfirmatioTitle({ name }: ClientConfirmatioTitleProps) {
  return (
    <Text textAlign="center" color="#979797" fontSize="18px" lineHeight="23px">
      É você, {name} ?
    </Text>
  );
}
