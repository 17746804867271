import { Country, isSupportedCountry } from "react-phone-number-input";

export const countryCodeValidation = (value: string) => {
  try {
    const isValidCountryCode = isSupportedCountry(value);

    if (!isValidCountryCode) {
      return "BR";
    }

    const country = value as Country;

    return country;
  } catch (error) {
    console.error("Country validation error:", error);
    return;
  }
};
