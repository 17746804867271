import { Flex, Text } from "@chakra-ui/react";

interface TimerToastProps {
  show: boolean;
  message: string;
  color: string;
}

export function TimerToast({ color, show, message }: TimerToastProps) {
  return show ? (
    <Flex
      borderRadius="16px"
      position="fixed"
      top="24px"
      right="24px"
      bg={color}
      padding="8px"
    >
      <Text color="#ffffff" margin="auto" fontSize="18px">
        {message}
      </Text>
    </Flex>
  ) : (
    <></>
  );
}
