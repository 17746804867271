import { AngryIcon } from "../../molecules/EmoticonQuestionIcon/AngryIcon";
import { FrownIcon } from "../../molecules/EmoticonQuestionIcon/FrownIcon";
import { GrinStarsIcon } from "../../molecules/EmoticonQuestionIcon/GrinStarsIcon";
import { MehIcon } from "../../molecules/EmoticonQuestionIcon/MehIcon";
import { SmileIcon } from "../../molecules/EmoticonQuestionIcon/SmileIcon";

interface EmoticonIconQuestionProps {
  selected: number;
  index: number;
}

export function EmoticonIconQuestion({
  selected,
  index,
}: EmoticonIconQuestionProps) {
  switch (index) {
    case 0:
      return <AngryIcon selected={selected} />;
    case 1:
      return <FrownIcon selected={selected} />;
    case 2:
      return <MehIcon selected={selected} />;
    case 3:
      return <SmileIcon selected={selected} />;
    case 4:
      return <GrinStarsIcon selected={selected} />;
    default:
      return <></>;
  }
}
