import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Routes from "./routes";
import { theme } from "./styles/theme";
import ReactGA from "react-ga";

import { ErrorBoundary } from "react-error-boundary";
function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert" style={{ maxWidth: "300px" }}>
      <p>{error.message}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function App() {
  ReactGA.initialize("UA-202568299-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default App;
