import { SpinWheel } from "../../components/SpinWheel";
import { useSpin } from "../../hooks/useSpin";
import { SpinWheelResult } from "../../components/SpinWheel/spinWheelResult";

export function Roulette() {
    const segments = [
        { index: 1, color: "#ff9000", value: "1 Petisco" },
        { index: 2, color: "#0088a1", value: "1 Chop" },
        { index: 3, color: "#ff9000", value: "Talvez na próxima" },
        { index: 4, color: "#0088a1", value: "Coca-cola" },
        { index: 5, color: "#ff9000", value: "2 Pastéis" },
        { index: 6, color: "#0088a1", value: "Almoço" },
        { index: 7, color: "#ff9000", value: "10% de Desconto" },
        { index: 8, color: "#0088a1", value: "1 Suco" },
    ];

    const { rotation, handleSpin, result } = useSpin(segments);

    return (
        <SpinWheel.Root>
            <SpinWheel.Content>
                <SpinWheel.Pointer />
                <SpinWheel.Button onClick={handleSpin} />
                <SpinWheel.Body rotation={rotation}>
                    {segments.map((segment) => (
                        <SpinWheel.Segment
                            key={segment.index}
                            index={segment.index}
                            color={segment.color}
                        >
                            <SpinWheel.SegmentContent value={segment.value} />
                        </SpinWheel.Segment>
                    ))}
                </SpinWheel.Body>
            </SpinWheel.Content>
            {result !== null && (
                <SpinWheelResult>
                    {result}
                </SpinWheelResult>
            )}
        </SpinWheel.Root>
    );
}