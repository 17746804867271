import { Box, Flex } from "@chakra-ui/react";
import { TbDropletFilled } from "react-icons/tb";

export function SpinWheelPointer() {
  return (
    <Flex
      position="absolute"
      top={["-10px", "-15px", "-20px", "-25px"]}
      left={["42%", "43%", "44%", "45%"]}
      transformOrigin="top center"
      justifyContent="center"
      alignItems="center"
      userSelect="none"
      zIndex={2}
    >
      <Box
        as={TbDropletFilled}
        transform="rotate(181deg)"
        color="#de1414"
        fontSize={["30px", "35px", "50px", "60px"]}
      />
    </Flex>
  );
}
