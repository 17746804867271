import { ModalBody } from "../../../atoms/Modal/ModalBody";
import { Flex } from "@chakra-ui/react";
import { ModalStatusIcon } from "../../../atoms/Icon/ModalStatusIcon";
import { VisitText } from "../../../atoms/Text/VisitText";
import { ElementType } from "react";

interface ModalStatusBodyProps {
  icon: ElementType;
  text: string;
}

export function ModalStatusBody({ icon, text }: ModalStatusBodyProps) {
  return (
    <ModalBody>
      <Flex
        bg="white"
        align="center"
        justify="space-between"
        flexDir="column"
        borderRadius="15px"
      >
        <ModalStatusIcon color="#0B6E4F" icon={icon} />
        <VisitText text={text} />
      </Flex>
    </ModalBody>
  );
}
