import { FaAngry, FaRegAngry } from "react-icons/fa";

interface AngryIconProps {
  selected: number;
}

export function AngryIcon({ selected }: AngryIconProps) {
  return selected === 1 ? (
    <FaAngry color="#dd545e" size={40} />
  ) : (
    <FaRegAngry color="#dd545e" size={40} />
  );
}
