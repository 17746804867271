import { Icon, Flex, Text } from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";

interface CheckedVisitCardProps {
  value: string;
  iconColor?: string;
}

export function CheckedVisitCard({
  value,
  iconColor = "#D9D9D9",
}: CheckedVisitCardProps) {
  return (
    <Flex
      alignItems="center"
      flexDir="column"
      justifyContent="space-around"
      borderRadius="14px"
      w={{ base: "55px", md: "60px" }}
      h={{ base: "55px", md: "60px" }}
      bg="white"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15);"
    >
      <Icon as={FiCheckCircle} color={iconColor} fontSize="21px" />
      <Text
        fontWeight="bold"
        color="gray.450"
        fontSize="16px"
        lineHeight="18px"
      >
        {value}
      </Text>
    </Flex>
  );
}
