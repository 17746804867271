import { Box, } from "@chakra-ui/react"

interface SpinWheelSegmentContentProps {
    value: string
}

export function SpinWheelSegmentContent({ value }: SpinWheelSegmentContentProps) {

    return (
        <Box
            position="relative"
            fontFamily="'Work Sans', sans-serif"
            transform="rotate(40deg)"
            fontSize="sm"
            fontWeight="700"
            color="#fff"
            ml="70px"
            mr="70px"
            textShadow="3px 5px 2px rgba(0, 0, 0, 0.08)"
            textAlign="center"
        >
            {value}
        </Box>
    )
}