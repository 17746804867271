import { Checkbox } from "../../../atoms/Checkbox";
import { OptionDivider } from "../../../atoms/Divider/OptionsDivider";

interface IProps {
  value: any;
  label: string;
  setValue: (value: string) => void;
  selectColor: string;
  defaultChecked?: boolean;
  isRequired?: boolean;
}
export function CheckBoxGroupSearch({
  value,
  setValue,
  selectColor,
  label,
  defaultChecked = false,
  isRequired = false,
}: IProps) {
  return (
    <>
      <Checkbox
        value={value}
        label={label}
        setValue={setValue}
        color={selectColor}
        defaultChecked={defaultChecked}
        isRequired={isRequired}
      />
      <OptionDivider color={selectColor} />
    </>
  );
}
