import { Icon, Flex } from "@chakra-ui/react";
import { FaGift } from "react-icons/fa";
import { TitleDefault } from "../../../atoms/Text/TitleDefault";

interface ModalGiftListTitleProps {
  color: string;
  title: string;
}

export function ModalGiftListTitle({ color, title }: ModalGiftListTitleProps) {
  return (
    <Flex alignItems="center" justify="center" mb="10px">
      <Icon as={FaGift} color={color} mr="5px" fontSize="22px" />
      <TitleDefault title={title} />
    </Flex>
  );
}
