import { useCallback, useEffect, useMemo, useState } from "react";
import { ChakraProvider, CheckboxGroup, extendTheme } from "@chakra-ui/react";
import { UnstyledInput } from "../../atoms/Input/UnstyledInput";
import { CheckBoxGroupSearch } from "../../molecules/CheckboxGroup/CheckBoxGroupSearch";
import { FormControlQuestionSearch } from "../../molecules/FormControl/FormControlQuestionSearch";
import { CheckBoxGroupOther } from "../../molecules/CheckboxGroup/CheckBoxGroupOther";
import { InputGroup } from "../../molecules/InputGroup";
import { FormErrorMessageDefault } from "../../atoms/FormErrorMessage/FormErrorMessageDefault";
import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";
import { ClearButton } from "../../atoms/Button/ClearButton";

interface IOptions {
  value: any;
  label: string;
}
interface IProps {
  label: string;
  options: IOptions[];
  onChange: (value: string) => void;
  name: string;
  isRequired: boolean;
  selectColor: string;
  other?: boolean;
  description?: string;
  defaultValue?: string;
  error?:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | boolean
    | undefined;
  handleTrigger?: (value: string) => void;
  questionId?: string;
  updateError?: (value: string, error: boolean) => void;
}

interface Option {
  option: string;
}

export function CheckboxQuestion(props: IProps) {
  const [value, setValue] = useState<string>(props.defaultValue ?? "");
  const [hasError, setHasError] = useState<boolean>();
  const [others, setOthers] = useState("");

  const [selectOther, setSelectOther] = useState<boolean>(false);

  useEffect(() => {
    setHasError(!!props.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  const formatDefaultValue = useMemo(() => {
    if (props.defaultValue) {
      let parsedDefaultValue: { option: string }[] = JSON.parse(
        props.defaultValue
      );
      let formatDefaultValue: string[] = [];
      let otherInput = "";
      parsedDefaultValue.forEach((obj) => {
        let index = props.options.findIndex(
          (option) => option.label === obj.option
        );

        if (index >= 0) {
          formatDefaultValue.push(obj.option);
        } else {
          setSelectOther(true);
          formatDefaultValue.push("Outros");
          otherInput = obj.option;
        }
      });

      return {
        others: otherInput,
        options: formatDefaultValue,
      };
    }
    return {
      others: "",
      options: [],
    };
  }, [props.defaultValue, props.options]);

  const [selected, setSelected] = useState<string[]>(
    formatDefaultValue.options
  );

  useEffect(() => {
    setSelected(formatDefaultValue.options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatDefaultValue]);

  const theme = extendTheme({
    components: {
      Radio: {
        baseStyle: {
          control: {
            _checked: {
              bg: props.selectColor,
              borderColor: props.selectColor,
              _hover: {
                bg: props.selectColor,
                borderColor: props.selectColor,
              },
            },
          },
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            _checked: {
              bg: props.selectColor,
              borderColor: props.selectColor,
              _hover: {
                bg: props.selectColor,
                borderColor: props.selectColor,
              },
            },
          },
        },
      },
    },
  });
  const handleCheckbox = useCallback(
    (event: any) => {
      const newValue = updateValue(
        event.target.value,
        value,
        event.target.checked
      );
      setValue(newValue);
      props.onChange(newValue);
      if (props.updateError && props.questionId && props.error) {
        props.updateError(
          `quizResponses.${props.questionId}`,
          props.error as boolean
        );
      }
      setSelected((prev) =>
        event.target.checked
          ? [...prev, event.target.value]
          : prev.filter((item) => item !== event.target.value)
      );
    },
    [value, props]
  );

  const handleUpdateOther = useCallback(
    (event: any) => {
      const newValue = updateValue(event.target.value, value, true, others);
      setValue(newValue);
      props.onChange(newValue);
      setOthers(event.target.value);
      setSelected((prev) => [...prev, "Outros"]);
    },
    [value, others, props]
  );

  useEffect(() => {
    if (!selectOther) {
      const newValue = updateValue(undefined, value, false, others);
      if (newValue === "[]") {
        setValue("");
        props.onChange("");
        setOthers("");
        if (props.error) {
        }
      } else {
        setValue(newValue);
        props.onChange(newValue);
        setOthers("");
      }
    }
  }, [value, selectOther, others, props]);

  function updateValue(
    newValue: string | undefined,
    oldValue: string,
    isChecked: boolean,
    otherOption?: string
  ): string {
    let options: Option[] = [];
    if (oldValue !== "") {
      options = JSON.parse(oldValue) as Option[];
    }

    if (isChecked && newValue) {
      options.push({ option: newValue });
    } else if (!isChecked && newValue) {
      options = options.filter((option) => option.option !== newValue);
    } else if (otherOption) {
      options = options.filter((option) => option.option !== otherOption);
    }

    return JSON.stringify(options);
  }

  return (
    <ChakraProvider theme={theme}>
      <FormControlQuestionSearch
        isRequired={props.isRequired}
        label={props.label}
        name={props.name}
        description={props.description}
      >
        {hasError && <FormErrorMessageDefault error="Questão obrigatória" />}

        <CheckboxGroup
          defaultValue={formatDefaultValue.options}
          value={selected}
          onChange={(value) => {
            setSelected(value as string[]);
          }}
        >
          {props.options.map((option) => (
            <CheckBoxGroupSearch
              selectColor={props.selectColor}
              value={option.value}
              label={option.label}
              setValue={handleCheckbox}
              isRequired={value.length === 0 && props.isRequired}
            />
          ))}
          {props.other && (
            <CheckBoxGroupOther
              setSelectOther={setSelectOther}
              selectColor={props.selectColor}
            />
          )}
        </CheckboxGroup>

        {props.other && selectOther && (
          <InputGroup
            selectColor={props.selectColor}
            name={`input-other-${props.name}`}
            setValue={handleUpdateOther}
            error=""
            defaultValue={formatDefaultValue.others}
          />
        )}
        <UnstyledInput
          name={props.name}
          id={props.name}
          isRequired={props.isRequired}
          type="hidden"
        />
      </FormControlQuestionSearch>
      {!props.isRequired && (value || selectOther) && (
        <ClearButton
          onClick={() => {
            setValue("");
            setOthers("");
            setSelected([]);
            setSelectOther(false);
            props.onChange("");
          }}
        />
      )}
    </ChakraProvider>
  );
}
