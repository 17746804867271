import { Box, useMediaQuery } from "@chakra-ui/react";
import { useMemo } from "react";
import { RadioCheckbox } from "../../../atoms/Radio/RadioCheckbox";
import { RadioInput } from "../../../atoms/Radio/RadioInput";

export function RadioCard(props: any) {
  const [isLargerThan360] = useMediaQuery("(min-width: 360px)");
  const [isLargerThan400] = useMediaQuery("(min-width: 400px)");
  const [isLargerThan500] = useMediaQuery("(min-width: 500px)");

  const margin = useMemo(() => {
    if (!isLargerThan360) {
      return "4px";
    }

    if (!isLargerThan400) {
      return "1px";
    }

    if (!isLargerThan500) {
      return "2px";
    }
    return "4px";
  }, [isLargerThan360, isLargerThan400, isLargerThan500]);

  return (
    <Box as="label" mx={margin}>
      <RadioInput {...props} />
      <RadioCheckbox {...props} />
    </Box>
  );
}
