import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useNavigate, useParams } from "react-router-dom";

import { useStoreClient } from "../../hooks/storeClient";

export function LoaderStoreClient() {
  const { company, feature } = useStoreClient();
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (company.id) {
      if (company.has_create_client_independent) {
        navigate(`/cadastro/${slug}`);
      } else {
        navigate(`/cadastro/${slug}/indisponivel`);
      }
    }
  }, [company, navigate, slug]);

  useEffect(() => {
    if (feature.facebook_pixel) {
      ReactPixel.init(feature.facebook_pixel);
      ReactPixel.pageView();
    }
    if (feature.google_tag_manager) {
      TagManager.initialize({ gtmId: feature.google_tag_manager });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: window.location.pathname,
        },
      });
    }

    if (feature.google_analytics) {
      ReactGA.initialize(feature.google_analytics, {
        gaOptions: { name: "Dynamic" },
      });
      ReactGA.ga(
        "Dynamic.send",
        "pageview",
        window.location.pathname + window.location.search
      );
    }
  }, [
    feature.facebook_pixel,
    feature.google_analytics,
    feature.google_tag_manager,
  ]);
  return (
    <Flex alignItems="center" justify="center" display="flex">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={company.color}
        size="xl"
      />
    </Flex>
  );
}
