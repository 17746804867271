import { Button, Spinner } from "@chakra-ui/react";
import { shade } from "polished";
import { validIfColorIsValid } from "../../../../utils/color";
import { ReactNode } from "react";
interface LoadingSolidButtonProps {
  bg: string;
  children: ReactNode;
  color: string;
  loading: boolean;
  onClick: () => void;
}

export function LoadingSolidButton({
  bg,
  color,
  children,
  loading = false,
  onClick,
}: LoadingSolidButtonProps) {
  return (
    <Button
      bg={bg}
      color={color}
      onClick={onClick}
      _hover={{
        backgroundColor: shade(0.2, validIfColorIsValid(bg) ? bg : "#000000"),
      }}
      _active={{
        backgroundColor: shade(0.2, validIfColorIsValid(bg) ? bg : "#000000"),
      }}
      disabled={loading}
    >
      {loading ? (
        <>
          <Spinner mr="3" /> Baixando...
        </>
      ) : (
        `${children}`
      )}
    </Button>
  );
}
