import { FormLabel, Box } from "@chakra-ui/react";
interface ReactProps {
  text: string;
}
export function QuestionSearchLabel({ text }: ReactProps) {
  return (
    <FormLabel
      mt="4"
      mb="1"
      fontWeight="400"
      size="16px"
      lineHeight="22px"
      color="#363636"
    >
      <Box
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ all: "unset" }}
      />
    </FormLabel>
  );
}
