import {
  Modal,
  ModalOverlay,
  ModalBody,
  useDisclosure,
  ModalContent,
  Flex,
  Box,
} from "@chakra-ui/react";
import { ClientConfirmatioTitle } from "../../../atoms/Text/ClientConfirmationTitle";
import { DinamicClickText } from "../../../atoms/Text/DinamicClickText";
import { DinamicSolidButton } from "../../../atoms/Button/DinamicSolidButton";
import { useCallback, useState } from "react";
import { api } from "../../../../services/api";

interface ClientConfirmationModalFidelityProgramProps {
  name: string;
  email: string;
  company: {
    id: string;
    slug: string;
    color: string;
  };
  search: {
    slug: string;
  };
  fidelityProgram: {
    id: string;
  };
  link: string;
  setLink: (link: string) => void;
}
export function ClientConfirmationModalFidelityProgram({
  name,
  company,
  link,
  setLink,
  email,
  fidelityProgram,
  search,
}: ClientConfirmationModalFidelityProgramProps) {
  const { onClose } = useDisclosure();
  const [onOpen, setOnOpen] = useState(true);
  const handleAddClientVisit = useCallback(async () => {
    const response = await api.post(`/visits/${fidelityProgram.id}/update`, {
      company_id: company.id,
      email: email,
    });
    if (response.data.coupon.id === undefined) {
      return `/programa-fidelidade/${company.slug}/${search.slug}/${response.data.visit.client_id}/sucesso?visit=${response.data.new_visit}`;
    } else {
      return `/programa-fidelidade/${company.slug}/${search.slug}/${response.data.visit.client_id}/sucesso/${response.data.coupon.id}?visit=${response.data.new_visit}`;
    }
  }, [company.id, company.slug, email, fidelityProgram.id, search.slug]);
  return (
    <Modal isOpen={onOpen} onClose={onClose} isCentered={true} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ClientConfirmatioTitle name={name} />

          <Flex
            align="center"
            justifyContent="center"
            margin="auto"
            my={5}
            w="100%"
          >
            <DinamicClickText
              text="Não, alterar conta"
              onClick={() => {
                setLink(link);
                setOnOpen(false);
                localStorage.removeItem(`@B2S-${company.slug}:user`);
              }}
            />
            <Box mx={4}>
              <DinamicSolidButton
                bg={company.color}
                color="white"
                onClick={async () => {
                  const newLink = await handleAddClientVisit();
                  setLink(newLink);
                  setOnOpen(false);
                }}
              >
                Sim
              </DinamicSolidButton>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
