import { Progress } from "@chakra-ui/react";

interface SearchProgressBarProps {
  value: number;
  max: number;
}

export function SearchProgressBar({ value, max }: SearchProgressBarProps) {
  return (
    <Progress
      colorScheme="green"
      height="5px"
      value={value}
      min={0}
      max={max}
      zIndex="2"
    />
  );
}
