import { SearchColorText } from "../../components/atoms/SearchColorText";
import { useStoreClient } from "../../hooks/storeClient";

export function DisabledStoreClient() {
  const { company } = useStoreClient();
  return (
    <SearchColorText
      text={`${company.genre === "M" ? "O " : "A "}${
        company.name
      } não está aceitando cadastro no momento`}
      color="#000"
    />
  );
}
