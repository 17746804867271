import { ModalBody as ChakraModalBody } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ModalBodyProps {
  children: ReactNode;
}

export function ModalBody({ children }: ModalBodyProps) {
  return (
    <ChakraModalBody
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
    >
      {children}
    </ChakraModalBody>
  );
}
