import {
  ModalBody as ChakraModalBody,
  Flex,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FidelityProgramColorText } from "../../../atoms/Text/FidelityProgramColorText";
import { SubtitleDefault } from "../../../atoms/Text/SubtitleDefault";
import { ModalGiftListTitle } from "../../../molecules/Modal/ModalGiftListTitleProps";
interface ModalBonusProps {
  color: string;
  subtitle: string;
  title: string;
}

export function ModalBonus({ color, subtitle, title }: ModalBonusProps) {
  return (
    <ChakraModalBody
      as={Flex}
      textAlign="center"
      justifyContent="space-around"
      alignItems="center"
      flexDir="column"
      minH="100px"
    >
      <ModalCloseButton />
      <ModalGiftListTitle color="#f89f42" title="Prêmio" />
      <Flex flexDir="column" justify="space-around">
        <FidelityProgramColorText text={title} color={color} />
        <SubtitleDefault subtitle={subtitle} />
      </Flex>
    </ChakraModalBody>
  );
}
