import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../services/api";

interface Company {
  id: string;
  name: string;
  slug: string;
  logo: string;
  logo_url: string;
}
interface DegustContextProviderProps {
  children: ReactNode;
}

interface DegustContextProps {
  company: Company;
}
export const DegustContext = createContext<DegustContextProps>(
  {} as DegustContextProps
);

export function useDegustContext(): DegustContextProps {
  const context = useContext(DegustContext);

  if (!context) {
    throw new Error("useDegustContext must be used");
  }

  return context;
}

export function DegustContextProvider({
  children,
}: DegustContextProviderProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company>({} as Company);
  const query = new URLSearchParams(
    useLocation().search.replace(/\+/g, "sinal-adicao")
  );

  useEffect(() => {
    const [, , slug] = location.pathname.split("/");
    if (slug) {
      api.get(`/clientForms/degust/company/${slug}`).then((response) => {
        setCompany(response.data);
      });
    } else {
      if (query) {
        api.get(`/clientForms/degust/token?${query}`).then((response) => {
          if (response.data.definedUnit && response.data.isValid) {
            localStorage.setItem(
              `degust-${response.data.search_id}`,
              response.data.id
            );
          }
          setCompany(response.data.company);
          navigate(response.data.link);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DegustContext.Provider
      value={{
        company,
      }}
    >
      {children}
    </DegustContext.Provider>
  );
}
