import { Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SpinWheelResultProps {
    children: ReactNode;
}

export function SpinWheelResult({ children }: SpinWheelResultProps) {
    return (
        <Flex position="absolute" bottom="-70" color="black ">
            <Text bg="silver" borderRadius={5} p={2}>
                Resultado: {children}
            </Text>
        </Flex>
    );
}
