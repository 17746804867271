import { useCallback } from "react";
import { Checkbox, CheckboxGroup, Flex, Input } from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../services/api";
import { useFormFidelityProgram } from "../../hooks/formFidelityProgram";
import { SubmitButton } from "../../components/atoms/Button/SubmitButton";
import { TitleDefault } from "../../components/atoms/Text/TitleDefault";
import { SubtitleDefault } from "../../components/atoms/Text/SubtitleDefault";
import { Form } from "../../components/atoms/Form";
import { PrivacityText } from "../../components/atoms/Text/PrivacityText";
import { SearchSimpleLink } from "../../components/molecules/SearchSimpleLink";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlMaterialUI } from "../../components/templates/FormControlMaterialUI";
import { EmailInput } from "../../components/atoms/Input/EmailInput";

const createClientFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Formato inválido")
    .required("E-mail é obrigatório")
    .min(3, "O e-mail deve conter no mínimo 3 caracteres"),
  approved: yup.boolean().nullable(),
});
export function FidelityClient() {
  const { company, fidelityProgram } = useFormFidelityProgram();
  const { slug, search_slug } = useParams();
  const { register, handleSubmit, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(createClientFormSchema),
  });
  const navigate = useNavigate();
  const handleVisit: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        const response = await api.post(
          `/visits/${fidelityProgram.id}/update`,
          {
            company_id: company.id,
            email: formValue.email,
            approved: formValue.approved,
          }
        );
        localStorage.setItem(
          `@B2S-${company.slug}:user`,
          JSON.stringify({
            name: response.data.client.name,
            email: response.data.client.email,
            id: response.data.client.id,
          })
        );
        if (response.data.coupon.id === undefined) {
          navigate(
            `/programa-fidelidade/${slug}/${search_slug}/${response.data.visit.client_id}/sucesso?visit=${response.data.new_visit}`
          );
        } else {
          navigate(
            `/programa-fidelidade/${slug}/${search_slug}/${response.data.visit.client_id}/sucesso/${response.data.coupon.id}?visit=${response.data.new_visit}`
          );
        }
      } catch (err: any) {
        if (err.message === "Request failed with status code 404") {
          navigate(
            `/programa-fidelidade/${slug}/${search_slug}/cliente/cadastro?type=true`
          );
        }
      }
    },
    [company.slug, company.id, fidelityProgram.id, navigate, search_slug, slug]
  );
  const { errors } = formState;
  return (
    <>
      <TitleDefault title="Login" />
      <SubtitleDefault subtitle={fidelityProgram.name} />
      <Form id="login" onSubmit={handleSubmit(handleVisit)}>
        <FormControlMaterialUI
          text="E-mail"
          register={register}
          name="email"
          isRequired={true}
          error={errors.email}
          bg={company.color}
          input={EmailInput}
        />
        <CheckboxGroup>
          <Flex alignItems="start" justify="start" flexDir="column">
            <Checkbox {...register("approved")} isRequired={true} variant="">
              <PrivacityText link={company.privacy_policy_link} />
            </Checkbox>
          </Flex>
          <Input required={true} type="hidden" />
        </CheckboxGroup>
      </Form>
      <Flex justify="space-between" alignItems="center">
        <SearchSimpleLink
          link={`/programa-fidelidade/${company.slug}/${search_slug}/cliente/cadastro`}
          text="Não tenho cadastro"
        />
        <SubmitButton
          bg={company.color}
          color="white"
          text="Entrar"
          form="login"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </>
  );
}
