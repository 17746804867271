import { OptionDivider } from "../../../atoms/Divider/OptionsDivider";
import { Radio } from "../../../atoms/Radio/Radio";

interface IOption {
  name: string;
  value?: any;
}

interface IProps {
  option: IOption;
  selectColor: string;
  setSelectOther: (select: boolean) => void;
}
export function RadioGroupSearch({
  setSelectOther,
  option,
  selectColor,
}: IProps) {
  return (
    <>
      <Radio
        color={selectColor}
        label={option.name}
        value={option.value ? option.value : option.name}
        setValue={() => {
          setSelectOther(false);
        }}
      />
      <OptionDivider color={selectColor} />
    </>
  );
}
