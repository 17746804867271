import { HStack } from "@chakra-ui/react";
import { RadioProps, useRadioGroup } from "@chakra-ui/react";
import { RadioCard } from "../../../molecules/RadioCard/NumberRadioCard";
import { useEffect, useMemo, useState } from "react";
import { FormControlQuestionSearch } from "../../../molecules/FormControl/FormControlQuestionSearch";
import { FormatReplic } from "../../../templates/FormatReplic";
import { FormErrorMessageDefault } from "../../../atoms/FormErrorMessage/FormErrorMessageDefault";
import { Text } from "@chakra-ui/react";
import { nextStep } from "../../../../utils/nextStep";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { ClearButton } from "../../../atoms/Button/ClearButton";
interface IProps extends RadioProps {
  label: string;
  selectColor: string;
  name: string;
  description?: string;
  has_promoter_replica?: boolean;
  has_neutral_replica?: boolean;
  has_detractor_replica?: boolean;
  promoter_replica?: string;
  neutral_replica?: string;
  detractor_replica?: string;
  setValue: (nps: number, replica: string) => void;
  defaultValue?: string;
  defaultReplica?: string;
  error?:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | boolean
    | undefined;
  step?: number;
  setStep?: (value: number) => void;
  handleTrigger?: (value: string) => void;
  questionId?: string;
  updateError?: (value: string, error: boolean) => void;
}

export function CSATRadioQuestion(props: IProps) {
  const [hasError, setHasError] = useState<boolean>();
  const colors = ["#dd545e", "#e27a45", "#efc62e", "#8fd460", "#6fc66b"];
  const [selected, setSelected] = useState<string>(props.defaultValue ?? "-1");

  const options = ["1", "2", "3", "4", "5"];

  const [replica, setReplica] = useState<string>(props.defaultReplica ?? "");

  const [focus, setFocus] = useState<boolean>(false);

  const sortingValue = useMemo(() => {
    if (Number(selected) === 5) {
      return "promoter";
    } else if (Number(selected) === 4 || Number(selected) === 3) {
      return "neutral";
    } else if (Number(selected) <= 2 && Number(selected) > 0) {
      return "detractor";
    }
    return "";
  }, [selected]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    value: String(selected),
    onChange: (newValue) => {
      setSelected(newValue);
      props.setValue(Number(newValue), replica);
      if (props.updateError && props.questionId && props.error) {
        props.updateError(
          `quizResponses.${props.questionId}`,
          props.error as boolean
        );
      }
      if (props.step !== undefined && props.setStep !== undefined) {
        const checkNextStep = nextStep({
          checkValue: newValue,
          has_replica_detractor: props.has_detractor_replica,
          has_replica_neutral: props.has_neutral_replica,
          has_replica_promoter: props.has_promoter_replica,
          type: "CSAT",
        });
        if (checkNextStep) {
          props.setStep(props.step + 1);
          if (props.handleTrigger) {
            props.handleTrigger(`quizResponses.${props.questionId}`);
          }
        }
      }
    },
  });

  useEffect(() => {
    setHasError(!!props.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  const group = getRootProps();

  return (
    <>
      <FormControlQuestionSearch
        isRequired={props.isRequired}
        label={props.label}
        name={props.name}
        description={props.description}
      >
        {hasError && <FormErrorMessageDefault error="Questão obrigatória" />}
        <HStack justifyContent="center" {...group}>
          {options.map((option) => {
            const radio = getRadioProps({ value: option });

            return (
              <RadioCard
                key={option}
                {...radio}
                color={colors[Number(option) - 1]}
                borderColor={colors[Number(option) - 1]}
                bg="#fff"
                borderWidth="2px"
                selectColor={colors[Number(option) - 1]}
              >
                <Text fontWeight="bold">{option}</Text>
              </RadioCard>
            );
          })}
        </HStack>
      </FormControlQuestionSearch>
      <FormatReplic
        detractor_replica={props.detractor_replica}
        neutral_replica={props.neutral_replica}
        promoter_replica={props.promoter_replica}
        sortingValue={sortingValue}
        value={selected}
        has_detractor_replica={props.has_detractor_replica}
        has_neutral_replica={props.has_neutral_replica}
        has_promoter_replica={props.has_promoter_replica}
        focus={focus}
        color={props.selectColor}
        setValue={(value: any, result: string) => {
          props.setValue(value, result);
          setReplica(result);
        }}
        setReplica={(value: string) => setReplica(value)}
        setFocus={(value: boolean) => setFocus(value)}
        defaultReplica={replica}
      />
      {!props.isRequired && selected !== "-1" && (
        <ClearButton
          onClick={() => {
            setSelected("-1");
            setReplica("");
            setFocus(false);
            props.setValue(-1, "");
          }}
        />
      )}
    </>
  );
}
