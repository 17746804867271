import { Avatar, Flex, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";

import { Footer } from "../../components/templates/Footer";
import { useDegustContext } from "../../contexts/degustContext";

interface AdminProps {
  children?: ReactNode;
}

export function DegustTemplate({ children }: AdminProps) {
  const { company } = useDegustContext();
  return (
    <>
      <Helmet>
        <title>Falaê - Pesquisa</title>
        <link rel="icon" href="favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="logo192.png" />
      </Helmet>
      <Flex
        flexDir="column"
        justify="space-between"
        h="100vh"
        fontFamily="Mulish"
      >
        <Flex align="center" justify="center">
          <Flex
            h="120px"
            w="100vw"
            bg="white"
            borderRadius="0% 0% 50% 50%"
            boxShadow="0px 10px 12px rgba(0,0,0,0.05)"
            justify="center"
            align="center"
          >
            <Avatar
              src={company.logo_url}
              name={company.name}
              loading="lazy"
              size="xl"
              mx="auto"
              bg="transparent"
              borderRadius="50%"
            />
          </Flex>
        </Flex>
        <Flex
          maxW="500px"
          my="5px"
          justify="center"
          mx={{ base: "25px", md: "auto" }}
        >
          <Stack spacing={5} direction="column">
            {children}
          </Stack>
        </Flex>
        <Footer />
      </Flex>
    </>
  );
}
