import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SpinWheelSegmentProps {
    index: number;
    color: string;
    children: ReactNode;
}

export function SpinWheelSegment({ index, color, children }: SpinWheelSegmentProps) {
    return (
        <Box
            position="absolute"
            width="50%"
            height="50%"
            background={color}
            transformOrigin="bottom right"
            transform={`rotate(${45 * index}deg)`}
            clipPath="polygon(0 0, 61% 0, 100% 100%, 0 56%)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            userSelect="none"
            cursor="pointer"
        >
            {children}
        </Box>
    );
};
