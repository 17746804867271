import { FormControl } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FormControlFieldsetProps {
  children: ReactNode;
  isRequired?: boolean;
  error: boolean;
  focus: boolean;
  bg: string;
  id: string;
}

export function FormControlFieldset({
  children,
  bg,
  error,
  focus,
  id,
  isRequired,
}: FormControlFieldsetProps) {
  return (
    <FormControl
      isRequired={isRequired}
      as="fieldset"
      border={
        focus
          ? `1px solid ${bg}`
          : `1px solid ${error ? "#d32f2f" : "rgba(0, 0, 0, 0.12)"} `
      }
      borderRadius="4px"
      h="64px"
      alignItems="center"
      id={id}
    >
      {children}
    </FormControl>
  );
}
