import { Text, Flex, Box } from "@chakra-ui/react";

export function DegustEndControl() {
  return (
    <Flex alignItems="center" direction="column">
      <Flex alignItems="center" direction="column">
        <Box mt={5}>
          <Text mt="50px">Sua resposta foi realizada com sucesso! </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
