import { FieldValues, UseFormRegister } from "react-hook-form";

import { NPSRadioQuestionDefault } from "../../organisms/RadioQuestion/NPSRadioQuestion/NPSRadioQuestionDefault";
import { TextQuestionDefault } from "../../organisms/TextQuestion/TextQuestionDefault";

interface Props {
  selectColor: string;
  setNpsValue: (nps: string, replica: number) => void;
  setSuggestionValue: (suggestion: string) => void;
  suggestion_label: string;
  nps_label: string;
  nps_first: boolean;
  total_questions: number;
  register: UseFormRegister<FieldValues>;
  has_nps?: boolean;
  has_suggestion?: boolean;
}

export function NpsAndSuggestion({
  selectColor,
  setNpsValue,
  suggestion_label,
  nps_first,
  total_questions,
  nps_label,
  register,
  setSuggestionValue,
  has_nps = true,
  has_suggestion = true,
}: Props) {
  const position = nps_first ? 1 : total_questions + 1;
  return (
    <>
      <>
        {has_nps && (
          <NPSRadioQuestionDefault
            selectColor={selectColor}
            {...register(`nps`)}
            label={`${position} - ${nps_label}`}
            setValue={(nps: number) => {
              setNpsValue(`nps`, nps);
            }}
            isRequired={true}
          />
        )}
        {has_suggestion && (
          <TextQuestionDefault
            isRequired={false}
            label={`${total_questions + 2} - ${suggestion_label}`}
            {...register(`discursive_question`)}
            setValue={(value: string) => {
              setSuggestionValue(value);
            }}
            selectColor={selectColor}
          />
        )}
      </>
    </>
  );
}
