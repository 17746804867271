import { Loader } from "../../components/templates/Loader";

export function DegustLoader() {
  return (
    <Loader
      isLoading={false}
      whiteLabel={{
        logo_vertically_url: "",
        name: "Falaê",
        primary_color: "#2b88a1",
      }}
      children={<></>}
    />
  );
}
