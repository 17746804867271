import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface BackgroundCircleIconProps {
  bg: string;
  children: ReactNode;
}

export function BackgroundCircleIcon({
  bg,
  children,
}: BackgroundCircleIconProps) {
  return (
    <Box
      bg={bg}
      borderRadius="50%"
      fontSize="15px"
      h="30px"
      textAlign="center"
      alignItems="center"
      w="30px"
      zIndex="3"
    >
      {children}
    </Box>
  );
}
