import { FormLabel } from "@chakra-ui/react";

interface FormLabelLegendProps {
  text: string;
  htmlFor: string;
  color?: string;
  focus?: boolean;
}
export function FormLabelLegend({
  focus,
  text,
  htmlFor,
  color = "rgba(0, 0, 0, 0.6)",
}: FormLabelLegendProps) {
  return (
    <FormLabel
      as="legend"
      ml="10px"
      color={focus ? color : "rgba(0, 0, 0, 0.6)"}
      fontSize="12px"
      lineHeight="16px"
      htmlFor={htmlFor}
    >
      {text}
    </FormLabel>
  );
}
