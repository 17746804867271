import { useMemo, useState } from "react";

import { useFormFidelityProgram } from "../../hooks/formFidelityProgram";
import { FidelityProgramButtonList } from "../../components/organisms/FidelityProgramButtonList";
import { TitleDefault } from "../../components/atoms/Text/TitleDefault";
import { SubtitleDefault } from "../../components/atoms/Text/SubtitleDefault";
import { ModalGiftList } from "../../components/templates/Modal/ModalGiftList";

import { ClientConfirmationModalFidelityProgram } from "../../components/templates/Modal/ClientConfirmationModalFidelityProgram";
import { Loader } from "../../components/templates/Loader";
export function IndexFidelityProgram() {
  const { search, company, bonuses, fidelityProgram, whiteLabel } =
    useFormFidelityProgram();
  const [link, setLink] = useState("");
  const linkSearch = useMemo(() => {
    return search.has_page
      ? `/formularios/${company.slug}/${search.slug}/inicial`
      : `/formularios/${company.slug}/${search.slug}`;
  }, [company.slug, search.has_page, search.slug]);
  const user = localStorage.getItem(`@B2S-${company.slug}:user`);

  return (
    <Loader isLoading={!!company.color} whiteLabel={whiteLabel}>
      {user ? (
        <>
          <SubtitleDefault subtitle={`Olá, ${String(JSON.parse(user).name)}`} />
          <TitleDefault title="Para onde deseja ir?" />
          <ClientConfirmationModalFidelityProgram
            name={String(JSON.parse(user).name)}
            company={company}
            email={String(JSON.parse(user).email)}
            fidelityProgram={fidelityProgram}
            search={search}
            link={`/programa-fidelidade/${company.slug}/${search.slug}/cliente/cadastro`}
            setLink={(linkFidelity) => setLink(linkFidelity)}
          />
        </>
      ) : (
        <>
          <TitleDefault title="Para onde deseja ir?" />
        </>
      )}
      {bonuses && bonuses.length > 0 ? (
        <ModalGiftList
          color={company.color}
          buttonBg={company.color}
          buttonTextColor="white"
          giftList={bonuses
            .sort((a, b) => (a.total_visits > b.total_visits ? 1 : -1))
            .map((bonus) => {
              return {
                color: "#2B4162",
                subtitle: bonus.name,
                type: `${bonus.total_visits}ª visita`,
              };
            })}
        />
      ) : (
        <></>
      )}
      <FidelityProgramButtonList
        searchLink={linkSearch}
        primaryColor={company.color ?? "#ffffff"}
        secondaryColor={company.secondary_color ?? "#ffffff"}
        textColor="white"
        fidelityProgramLink={
          user
            ? link
            : `/programa-fidelidade/${company.slug}/${search.slug}/cliente`
        }
        external_link={search.external_link}
        name_external_link={search.name_external_link}
      />
    </Loader>
  );
}
