import { FaGrinStars, FaRegGrinStars } from "react-icons/fa";

interface GrinStarsIconProps {
  selected: number;
}

export function GrinStarsIcon({ selected }: GrinStarsIconProps) {
  return selected === 5 ? (
    <FaGrinStars color="#6fc66b" size={40} />
  ) : (
    <FaRegGrinStars color="#6fc66b" size={40} />
  );
}
