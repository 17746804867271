import { useFormClient } from "../../../hooks/formClient";

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { api } from "../../../services/api";
import { Flex, SimpleGrid } from "@chakra-ui/react";

import { MultipleChoiceRadioQuestionDefault } from "../../../components/organisms/RadioQuestion/MultipleChoiceRadioQuestion/MultipleChoiceRadioQuestionDefault";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { Loader } from "../../../components/templates/Loader";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
interface Page {
  id: string;
  name: string;
}

interface FormPage {
  page: string;
}

const pageValidation = yup.object().shape({
  page: yup.string().uuid("Valor inválido").required("Obrigatério"),
});

export function IndexPageControl() {
  const { search, company, questionQuery, whiteLabel } = useFormClient();
  const { slug, search_slug, client_id, consumption_id } = useParams();
  const [pages, setPages] = useState<Page[]>([]);
  const navigate = useNavigate();

  const {
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(pageValidation),
    defaultValues: {
      page: "",
    },
  });

  useEffect(() => {
    if (
      !client_id &&
      search.reverse_order &&
      search.anonymous_search &&
      !search.is_enps
    ) {
      navigate(`/formularios/${company.slug}/${search.slug}/cliente/inicial`);
    }
    api.get(`clientForms/pages/${search.id}`).then((response) => {
      setPages(response.data);
      if (response.data && response.data.length === 1) {
        navigate(
          `/formularios/${company.slug}/${search.slug}/${response.data[0].id}${
            client_id ? `/${client_id}` : ""
          }${consumption_id ? `/${consumption_id}` : ""}?${questionQuery}`
        );
      }
    });
    if (search.id !== undefined && !search.active) {
      navigate(`/formularios/${company.slug}/${search.slug}/desativada`);
    }
  }, [
    client_id,
    company.slug,
    consumption_id,
    navigate,
    questionQuery,
    search.active,
    search.anonymous_search,
    search.id,
    search.is_enps,
    search.reverse_order,
    search.slug,
  ]);

  const handlePageSubmit: SubmitHandler<FormPage> = useCallback(
    (formValue) => {
      navigate(
        `/formularios/${slug}/${search_slug}/${formValue.page}${
          client_id ? "/" + client_id : ""
        }`
      );
    },
    [navigate, client_id, slug, search_slug]
  );

  return (
    <Loader isLoading={pages && pages.length !== 0} whiteLabel={whiteLabel}>
      <HTMLText html={search.form_message} />
      <Flex direction="column">
        <SimpleGrid
          columns={1}
          mt="3"
          as={"form"}
          id="choose-page"
          onSubmit={handleSubmit(handlePageSubmit)}
        >
          <MultipleChoiceRadioQuestionDefault
            label={search.question_page || ""}
            name="page"
            options={
              pages
                ? pages
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((page) => ({ name: page.name, value: page.id }))
                : []
            }
            setValue={(value: string) => {
              setValue("page", value, {
                shouldValidate: true,
              });
            }}
            isRequired
            selectColor={company.color}
            error={errors.page !== undefined}
          />
        </SimpleGrid>
        <Flex justify="flex-end">
          <SubmitButton
            bg={
              company.secondary_color === undefined
                ? "#2b88a1"
                : company.secondary_color
            }
            text="Próximo"
            form="choose-page"
          />
        </Flex>
      </Flex>
    </Loader>
  );
}
