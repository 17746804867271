import { SearchColorText } from "../../../components/atoms/SearchColorText";

export function IndexClosedControl() {
  return (
    <SearchColorText
      text="Essa pesquisa de satisfação já foi encerrada"
      color="#000"
    />
  );
}
