import { Replica } from "../../organisms/Replica";

interface FormatReplicProps {
  color: string;
  focus: boolean;
  setValue: (value: any, result: string) => void;
  setFocus: (value: boolean) => void;
  setReplica: (value: string) => void;
  value: string | number;
  sortingValue: string;
  has_detractor_replica?: boolean;
  has_promoter_replica?: boolean;
  has_neutral_replica?: boolean;
  detractor_replica?: string;
  neutral_replica?: string;
  promoter_replica?: string;
  defaultReplica?: string;
}

export function FormatReplic({
  color,
  focus,
  has_detractor_replica,
  has_neutral_replica,
  has_promoter_replica,
  detractor_replica,
  neutral_replica,
  promoter_replica,
  setFocus,
  setReplica,
  setValue,
  value,
  sortingValue,
  defaultReplica,
}: FormatReplicProps) {
  return (
    <>
      {has_promoter_replica && sortingValue === "promoter" && (
        <Replica
          label={promoter_replica}
          name="promoter"
          color={color}
          focus={focus}
          setValue={(value: any, result: string) => setValue(value, result)}
          setReplica={(value: string) => setReplica(value)}
          setFocus={(value: boolean) => setFocus(value)}
          value={value}
          replica={defaultReplica}
        />
      )}

      {has_neutral_replica && sortingValue === "neutral" && (
        <Replica
          label={neutral_replica}
          name="neutral"
          color={color}
          focus={focus}
          setValue={(value: any, result: string) => setValue(value, result)}
          setReplica={(value: string) => setReplica(value)}
          setFocus={(value: boolean) => setFocus(value)}
          value={value}
          replica={defaultReplica}
        />
      )}
      {has_detractor_replica && sortingValue === "detractor" && (
        <Replica
          label={detractor_replica}
          name="detractor"
          color={color}
          focus={focus}
          setValue={(value: any, result: string) => setValue(value, result)}
          setReplica={(value: string) => setReplica(value)}
          setFocus={(value: boolean) => setFocus(value)}
          value={value}
          replica={defaultReplica}
        />
      )}
    </>
  );
}
