import { useFormClient } from "../../../hooks/formClient";
import { AnsweredSearchOnePage } from "../SearchOnePage/answered";

export function IndexAnsweredControl() {
  const { search } = useFormClient();
  switch (search.type_form) {
    case "onepage":
      return <AnsweredSearchOnePage />;
    case "multipage":
      return <AnsweredSearchOnePage />;

    default:
      return <AnsweredSearchOnePage />;
  }
}
