import { Text } from "@chakra-ui/react";
interface FidelityProgramColorTextProps {
  text: string;
  color: string;
}

export function FidelityProgramColorText({
  text,
  color,
}: FidelityProgramColorTextProps) {
  return (
    <Text color={color} fontSize="14px" lineHeight="18px" textAlign="center">
      {text}
    </Text>
  );
}
