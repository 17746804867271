import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormClient } from "../../../hooks/formClient";
import { LoaderClientOnePage } from "../SearchOnePage/loaderClient";

export function IndexLoaderClientControl() {
  const navigate = useNavigate();
  const { search, company } = useFormClient();
  const user = localStorage.getItem(`@B2S-${company.slug}:user`);
  useEffect(() => {
    if (search.id !== undefined && !search.active) {
      navigate(`/formularios/${company.slug}/${search.slug}/desativada`);
    }
  }, [company.slug, navigate, search.active, search.id, search.slug]);
  switch (search.type_form) {
    case "onepage":
      return (
        <LoaderClientOnePage
          name={user ? String(JSON.parse(user).name) : undefined}
          email={user ? String(JSON.parse(user).email) : undefined}
        />
      );

    case "multipages":
      return (
        <LoaderClientOnePage
          name={user ? String(JSON.parse(user).name) : undefined}
          email={user ? String(JSON.parse(user).email) : undefined}
        />
      );

    default:
      return <LoaderClientOnePage />;
  }
}
