import { Stack } from "@chakra-ui/react";
import { SolidListButton } from "../../atoms/Button/SolidListButton";
import { ExternalLink } from "../../atoms/Link/ExternalLink";
import { InternalLink } from "../../atoms/Link/InternalLink";

interface SearchButtonListProps {
  searchLink: string;
  external_link?: string;
  name_external_link?: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  anotherLinks?: {
    name: string;
    text_color: string;
    button_color: string;
    link: string;
  }[];
}

export function SearchButtonList({
  external_link,
  name_external_link,
  textColor,
  primaryColor,
  searchLink,
  secondaryColor,
  anotherLinks,
}: SearchButtonListProps) {
  return (
    <Stack alignItems="center" flexDir="column" justify="space-between">
      <InternalLink link={searchLink}>
        <SolidListButton
          bg={primaryColor}
          text="Responder Pesquisa"
          color={textColor}
        />
      </InternalLink>
      {external_link && name_external_link && (
        <ExternalLink link={external_link}>
          <SolidListButton
            bg={secondaryColor}
            text={name_external_link}
            color={textColor}
          />
        </ExternalLink>
      )}
      {anotherLinks?.map((anotherLink) => (
        <ExternalLink link={anotherLink.link}>
          <SolidListButton
            bg={anotherLink.button_color}
            text={anotherLink.name}
            color={anotherLink.text_color}
          />
        </ExternalLink>
      ))}
    </Stack>
  );
}
