import { Radio as RadioChakra } from "@chakra-ui/react";

interface IProps {
  value: any;
  label: any;
  color: string;
  setValue?: (event: any) => void;
}
export function Radio({ setValue, label, value, color }: IProps) {
  return (
    <RadioChakra
      borderColor={color}
      value={value}
      fontSize="16px"
      color="#787878"
      onChange={setValue}
    >
      {label}
    </RadioChakra>
  );
}
