import { useState } from "react";

interface Segment {
    index: number;
    color: string;
    value: string;
}

export function useSpin(segments: Segment[]) {
    const [rotation, setRotation] = useState(0);
    const [result, setResult] = useState<string | null>(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [hasSpun, setHasSpun] = useState(false);

    const segmentAngle = 360 / segments.length;
    const spinDuration = 5000;

    const handleSpin = () => {
        if (hasSpun || isSpinning) return;
        setIsSpinning(true);
        setHasSpun(true);
        const randomSpins = Math.ceil(Math.random() * 3 + 5);
        const totalRotation = randomSpins * 360;
        const offsetRotation = Math.floor(Math.random() * 360);

        const finalRotation = totalRotation + offsetRotation;
        setRotation(finalRotation);

        setTimeout(() => {
            const normalizedRotation = finalRotation % 360;
            const alignedRotation = (360 - normalizedRotation + segmentAngle / 2) % 360;
            const winningSegmentIndex = Math.floor(alignedRotation / segmentAngle);
            const winningSegment = segments[winningSegmentIndex];

            setResult(winningSegment.value);
            setIsSpinning(false);
        }, spinDuration);
    };

    return { rotation, handleSpin, result, isSpinning };
}