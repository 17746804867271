import { Link } from "@chakra-ui/react";

interface OpenModalLinkProps {
  onClick: () => void;
  text: string;
  color: string;
}
export function OpenModalLink({ onClick, text, color }: OpenModalLinkProps) {
  return (
    <Link
      fontSize="14px"
      fontWeight="normal"
      lineHeight="18px"
      color={color}
      textAlign="center"
      onClick={onClick}
    >
      {text}
    </Link>
  );
}
