import { Button } from "@chakra-ui/react";
import { shade } from "polished";
import { validIfColorIsValid } from "../../../../utils/color";
interface OutlineListButtonProps {
  text: string;
  color: string;
}

export function OutlineListButton({ color, text }: OutlineListButtonProps) {
  return (
    <Button
      h="55px"
      w={{ base: "270px", md: "350px" }}
      variant="outline"
      borderColor={color}
      color={color}
      _hover={{
        backgroundColor: shade(
          0.2,
          validIfColorIsValid(color) ? color : "#000000"
        ),
        color: "white",
      }}
    >
      {text}
    </Button>
  );
}
