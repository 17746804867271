import { Input, InputProps } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { useState } from "react";
interface PhoneInputProps extends InputProps {
  name: string;
  id: string;
  isRequired?: boolean;
}
const InputBase: ForwardRefRenderFunction<HTMLInputElement, PhoneInputProps> = (
  { name, id, isRequired = false, ...rest },
  ref
) => {
  const [bornDate, setBornDate] = useState("");
  return (
    <Input
      w="calc(100% - 15px)"
      ml="10px"
      ref={ref}
      name={name}
      id={id}
      isRequired={isRequired}
      maxLength={10}
      value={bornDate}
      {...rest}
      onChange={(e: any) => {
        var value = e.target.value;
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{2})(\d{2})/g, "$1/$2");
        value = value.replace(/(\d)(\d{4})$/, "$1/$2");
        e.target.value = value;
        setBornDate(value);
        if (rest.onChange) {
          rest.onChange(e);
        }
      }}
    />
  );
};

export const BornDateInput = forwardRef(InputBase);
