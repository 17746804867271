import { Button } from "@chakra-ui/react";
import { shade } from "polished";
import { validIfColorIsValid } from "../../../../utils/color";

interface SolidListButtonProps {
  bg: string;
  text: string;
  color: string;
}

export function SolidListButton({ bg, color, text }: SolidListButtonProps) {
  return (
    <Button
      h="55px"
      w={{ base: "270px", md: "350px" }}
      bg={bg}
      color={color}
      fontSize="16px"
      lineHeight="16px"
      fontWeight="600"
      _hover={{
        backgroundColor: shade(0.2, validIfColorIsValid(bg) ? bg : "#000000"),
      }}
    >
      {text}
    </Button>
  );
}
