import { useCallback, useEffect } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { api } from "../../services/api";
import { useParams, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "../../hooks/query";
import { useFormFidelityProgram } from "../../hooks/formFidelityProgram";
import { SearchSimpleLink } from "../../components/molecules/SearchSimpleLink";
import { SubtitleDefault } from "../../components/atoms/Text/SubtitleDefault";
import { TitleDefault } from "../../components/atoms/Text/TitleDefault";
import { SubmitButton } from "../../components/atoms/Button/SubmitButton";
import { StoreClientForm } from "../../components/templates/StoreClient";
import { PrivacityText } from "../../components/atoms/Text/PrivacityText";
import { phoneNumberValidation } from "../../utils/phoneValidation";

const createClientFormData = yup.object().shape({
  name: yup
    .string()
    .required("Nome obrigatório")
    .min(3, "O nome deve conter no mínimo 3 caracteres"),
  surname: yup
    .string()
    .required("Sobrenome obrigatório")
    .min(3, "O sobrenome deve conter no mínimo 3 caracteres"),
  email: yup
    .string()
    .required("O E-mail é obrigatório")
    .email("Formato inválido")
    .min(6, "Número de caracteres muito pequeno"),
  phone: yup
    .string()
    .required("O Whatsapp é obrigatório")
    .test(
      "is-valid-phone",
      "Número de telefone inválido",
      phoneNumberValidation
    ),
  born_date: yup
    .string()
    .nullable()
    .matches(
      /(0?[1-9]|[12]\d|30|31)\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-1][0-9])/,
      "Formato inválido "
    ),
  approved: yup.boolean().nullable(),
});

export function StoreFidelityClient() {
  const { search, company, fidelityProgram } = useFormFidelityProgram();
  const { slug, search_slug } = useParams();
  const { register, handleSubmit, formState } = useForm({
    mode: "onBlur",
    resolver: yupResolver(createClientFormData),
  });
  const navigate = useNavigate();
  const query = useQuery();
  const toast = useToast();

  useEffect(() => {
    if (query.get("type")) {
      if (!toast.isActive("email")) {
        toast({
          id: "email",
          title: "E-mail não encontrado",
          status: "error",
          duration: 6000,
          isClosable: true,
          position: "top",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCreateClient: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      try {
        const response = await api.post(
          `/visits/${fidelityProgram.id}/create`,
          {
            name: formValue.name.trim() + " " + formValue.surname.trim(),
            email: formValue.email.trim(),
            phone: formValue.phone,
            born_date: formValue.born_date,
            company_id: search.company_id,
            approved: formValue.approved,
          }
        );
        localStorage.setItem(
          `@B2S-${company.slug}:user`,
          JSON.stringify({
            name: response.data.client.name,
            email: response.data.client.email,
            id: response.data.client.id,
          })
        );

        navigate(
          `/programa-fidelidade/${slug}/${search_slug}/${
            response.data.visit.client_id
          }/sucesso${
            response.data.coupon.id ? `/${response.data.coupon.id}` : ""
          }?visit=${response.data.new_visit}`
        );
      } catch (err) {
        console.log(err);
      }
    },
    [
      company.slug,
      fidelityProgram.id,
      navigate,
      search.company_id,
      search_slug,
      slug,
    ]
  );
  const { errors } = formState;

  return (
    <>
      <TitleDefault title="Cadastro" />
      <SubtitleDefault subtitle={fidelityProgram.name} />
      <Stack spacing={2}>
        <Flex
          as="form"
          flexDir="column"
          w="100%"
          id="store-client"
          onSubmit={handleSubmit(handleCreateClient)}
        >
          <StoreClientForm
            default_country={company.default_country}
            register={register}
            errors={errors}
            bg={company.color}
            has_cnpj={false}
            has_cpf={false}
            is_totem={search.is_totem}
          />
          <CheckboxGroup>
            <Flex alignItems="start" justify="start" flexDir="column">
              <Checkbox {...register("approved")} isRequired={true} variant="">
                <PrivacityText link={company.privacy_policy_link} />
              </Checkbox>
            </Flex>
            <Input required={true} type="hidden" />
          </CheckboxGroup>
        </Flex>
      </Stack>

      <Flex justify="space-between" alignItems="center">
        <SearchSimpleLink
          link={`/programa-fidelidade/${company.slug}/${search.slug}/cliente`}
          text="Já sou cadastrado"
        />
        <SubmitButton
          bg={company.color}
          color="white"
          text="Cadastrar"
          form="store-client"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </>
  );
}
