import { useCallback, useEffect, useState } from "react";
import * as htmlToImage from "html-to-image";
import Confetti from "react-confetti";
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import { DinamicSolidButton } from "../../../atoms/Button/DinamicSolidButton";
import { Coupon } from "../../Coupon";
import { api } from "../../../../services/api";
import { useParams } from "react-router";
import { GiftAccodionDescription } from "../../../organisms/Accordion/GiftAccordionDescription";
import download from "downloadjs";

interface CouponProps {
  id: string;
  code: string;
  expiration_date: Date;
  client_id: string;
  client: {
    name: string;
  };
  bonus: {
    name: string;
    bonuses_message?: string;
    total_visits: number;
  };
}

interface GiftCouponModalProps {
  company: {
    color: string;
    logo: string;
  };
  search: {
    coupon_message: string;
  };
  nameWithArticle: string;
  is_falae: boolean;
}
export function GiftCouponModal({
  company,
  search,
  nameWithArticle,
  is_falae,
}: GiftCouponModalProps) {
  const { innerWidth: width, innerHeight: height } = window;
  const { coupon_id } = useParams();
  const [onOpen, setOnOpen] = useState(true);

  const [coupon, setCoupon] = useState<CouponProps>({} as CouponProps);
  const [logo, setLogo] = useState("");
  const handleDownloadCoupon = useCallback(async () => {
    var node = document.getElementById("coupon");
    if (node) {
      await htmlToImage.toPng(node);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          download(dataUrl, `cupom.png`);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  useEffect(() => {
    if (typeof coupon_id !== typeof undefined) {
      api.get(`/coupons/${coupon_id}`).then((response) => {
        setCoupon(response.data);
      });

      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [coupon_id, company.logo]);

  return coupon.id ? (
    <>
      {onOpen && <Confetti width={width} height={height} />}
      <Modal
        isOpen={onOpen}
        size="sm"
        onClose={() => setOnOpen(false)}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <GiftAccodionDescription
              coupon={coupon}
              nameWithArticle={nameWithArticle}
              search={search}
            />
            <Flex align="center" justify="center" margin="auto" mb={4}>
              <DinamicSolidButton
                bg={company.color}
                color="white"
                onClick={() => {
                  handleDownloadCoupon();
                  setTimeout(() => {
                    setOnOpen(false);
                  }, 1000);
                }}
              >
                Fazer Download
              </DinamicSolidButton>
            </Flex>
            <Flex justify="center">
              <Coupon
                client={coupon.client}
                bonus={coupon.bonus}
                code={coupon.code}
                logo={logo}
                company={company}
                expiration_date={coupon.expiration_date}
                is_falae={is_falae}
              />
            </Flex>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : (
    <></>
  );
}
