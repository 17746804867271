import { FormControl } from "@chakra-ui/react";
import { ReactNode } from "react";

import { QuestionSearchDescription } from "../../../atoms/Text/QuestionSearchDescription";
import { QuestionSearchError } from "../../../atoms/Text/QuestionSearchError";
import { QuestionSearchLabel } from "../../../atoms/Text/QuestionSearchLabel";

interface IProps {
  name: string;
  children: ReactNode;
  label?: string;
  error?: string;
  description?: string;
  isRequired?: boolean;
}

export function FormControlQuestionSearch(props: IProps) {
  return (
    <FormControl isRequired={props.isRequired} my="3" w="100%">
      {props.label && <QuestionSearchLabel text={props.label} />}
      {props.description && (
        <QuestionSearchDescription description={props.description} />
      )}
      {props.children}
      {props.error && <QuestionSearchError text={props.error} />}
    </FormControl>
  );
}
