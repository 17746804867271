import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { api } from "../../../services/api";
import { FranchisesGroup } from "../SearchOnePage/franchises";

interface UnifyQrcode {
  id: string;
  franchise: {
    name: string;
  };
}
interface FranchiseGroup {
  radical?: string;
}

interface UnifyQrcodeFranchiseGroup {
  question: string;
  form_message: string;
  list_view: string;
}

interface IResponse {
  unifyQrcodes: UnifyQrcode[];
  unifyQrcodeFranchiseGroup: UnifyQrcodeFranchiseGroup;
  franchiseGroup: FranchiseGroup;
}
export function IndexLoaderUnifyQrcodesFranchisesGroup() {
  const { unify_qrcode_franchise_group_id } = useParams();
  const [unifyQrcodes, setUnifyQrcodes] = useState<UnifyQrcode[]>([]);
  const [unifyQrcodeFranchiseGroup, setUnifyQrcodeFranchiseGroup] =
    useState<UnifyQrcodeFranchiseGroup>({} as UnifyQrcodeFranchiseGroup);
  const [franchiseGroup, setFranchiseGroup] = useState<FranchiseGroup>(
    {} as FranchiseGroup
  );

  useEffect(() => {
    api
      .get<IResponse>(
        `clientForms/franchiseGrops/${unify_qrcode_franchise_group_id}`
      )
      .then((response) => {
        setUnifyQrcodes(response.data.unifyQrcodes);
        setUnifyQrcodeFranchiseGroup(response.data.unifyQrcodeFranchiseGroup);
        setFranchiseGroup(response.data.franchiseGroup);
      });
  }, [unify_qrcode_franchise_group_id]);

  return (
    <FranchisesGroup
      unifyQrcodes={unifyQrcodes}
      unifyQrcodeFranchiseGroup={unifyQrcodeFranchiseGroup}
      franchiseGroup={franchiseGroup}
    />
  );
}
