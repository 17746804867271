import { SimpleGrid } from "@chakra-ui/react";
import { useMemo } from "react";
import { formatDateDayAndMonth } from "../../../utils/formatDate";
import { CheckedVisitCard } from "../../molecules/CheckedVisitCard";
import { GiftBonusModalCard } from "../../templates/Modal/GiftBonusModalCard";

interface Visit {
  created_at: Date;
}

interface Bonus {
  name: string;
  total_visits: number;
}
interface FidelityProgramProgressProps {
  visits: Visit[];
  bonuses: Bonus[];
  buttonBg: string;
}

export function FidelityProgramProgress({
  visits,
  bonuses,
  buttonBg,
}: FidelityProgramProgressProps) {
  const nextSteps = useMemo(() => {
    const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    steps.splice(0, visits.length);
    return steps;
  }, [visits.length]);
  return (
    <SimpleGrid columns={5} spacing={3}>
      {visits.map((visit) => (
        <CheckedVisitCard
          value={formatDateDayAndMonth(visit.created_at)}
          iconColor="#0B6E4F"
        />
      ))}
      {nextSteps.map((visit) => (
        <>
          {bonuses.find((bonus) => bonus.total_visits === visit) ? (
            <GiftBonusModalCard
              value={String(visit).padStart(2, "0")}
              buttonBg={buttonBg}
              bonuses={bonuses}
              visit={visit}
            />
          ) : (
            <CheckedVisitCard value={String(visit).padStart(2, "0")} />
          )}
        </>
      ))}
    </SimpleGrid>
  );
}
