import { Flex, Image, Spinner } from "@chakra-ui/react";
import { ReactNode } from "react";

interface LoaderProps {
  children: ReactNode;
  isLoading: boolean;
  whiteLabel?: {
    logo_vertically_url: string;
    name: string;
    primary_color: string;
  };
}

export function Loader({ children, isLoading, whiteLabel }: LoaderProps) {
  return isLoading ? (
    <>{children}</>
  ) : (
    <Flex
      alignItems="center"
      justify="center"
      display="flex"
      position="relative"
    >
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color={whiteLabel ? whiteLabel.primary_color : "#2b88a1"}
        w="90px"
        h="90px"
      />
      {window.location.origin !== process.env.REACT_APP_WEB_URL &&
      window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL &&
      !whiteLabel ? (
        <></>
      ) : (
        <Image
          src={
            window.location.origin !== process.env.REACT_APP_WEB_URL &&
            window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
              ? whiteLabel?.logo_vertically_url
              : "https://b2s.s3.sa-east-1.amazonaws.com/falae/elemento-sem-fundo.png"
          }
          alt={whiteLabel ? whiteLabel.name : "Falaê"}
          w="40px"
          h="30px"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      )}
    </Flex>
  );
}
