import { Link, Text } from "@chakra-ui/react";

interface PrivacityTextProps {
  link?: string;
}

export function PrivacityText({ link }: PrivacityTextProps) {
  return (
    <Text
      color="#626262"
      fontSize="14px"
      fontWeight="18px"
      w="100%"
      textAlign="start"
    >
      {link ? (
        <>
          {" "}
          Li e concordo com a{" "}
          <Link href={link} target="_blank" color="blue">
            Política de Privacidade
          </Link>
        </>
      ) : (
        <>
          {" "}
          Aceito receber possíveis avisos e comunicados sobre promoções e
          benefícios para melhorar minha experiência.
        </>
      )}
    </Text>
  );
}
