import { Button } from "@chakra-ui/react";
import { shade } from "polished";
import { validIfColorIsValid } from "../../../../utils/color";
import { ReactNode } from "react";
interface DinamicOutilineButtonProps {
  children: ReactNode;
  color: string;
  onClick: () => void;
}

export function DinamicOutilineButton({
  color,
  children,
  onClick,
}: DinamicOutilineButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant="outline"
      borderColor={color}
      color={color}
      _hover={{
        backgroundColor: shade(
          0.2,
          validIfColorIsValid(color) ? color : "#000000"
        ),
        color: "white",
      }}
    >
      {children}
    </Button>
  );
}
