interface HTMLTextProps {
  html: string;
}

export function HTMLText({ html }: HTMLTextProps) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ marginBottom: "24px" }}
    />
  );
}
