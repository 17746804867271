import { OptionDivider } from "../../../atoms/Divider/OptionsDivider";
import { Radio } from "../../../atoms/Radio/Radio";

interface IProps {
  setSelectOther: (select: boolean) => void;
  selectColor: string;
}
export function RadioGroupOther({ setSelectOther, selectColor }: IProps) {
  return (
    <>
      <Radio
        color={selectColor}
        label="Outro"
        value="Outro"
        setValue={(event: any) => {
          setSelectOther(event.target.checked);
        }}
      />
      <OptionDivider color={selectColor} />
    </>
  );
}
