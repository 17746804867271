import { Button } from "@chakra-ui/react";
import { shade } from "polished";
import { ReactNode } from "react";
import { validIfColorIsValid } from "../../../../utils/color";
interface CloseModalButtonProps {
  onClose: () => void;
  children: ReactNode;
  bg: string;
  color: string;
}

export function CloseModalButton({
  onClose,
  color,
  children,
  bg,
}: CloseModalButtonProps) {
  return (
    <Button
      bg={bg}
      color={color}
      mr={3}
      onClick={onClose}
      _hover={{
        backgroundColor: shade(0.2, validIfColorIsValid(bg) ? bg : "#000000"),
      }}
    >
      {children}
    </Button>
  );
}
