import { Box } from "@chakra-ui/react";
import { useRadio, UseRadioProps } from "@chakra-ui/react";
import { ReactChild } from "react";
interface IProps extends UseRadioProps {
  children: ReactChild;
  selectColor: string;
}

export function RadioCheckbox(props: IProps) {
  const { getRadioProps } = useRadio(props);

  const checkout = getRadioProps();

  return (
    <Box
      {...checkout}
      cursor="pointer"
      borderWidth="1px"
      borderRadius="md"
      borderStyle="solid"
      background="#fff"
      boxShadow="md"
      _checked={{
        bg: props.selectColor,
        color: "#fff",
        borderColor: props.selectColor,
      }}
      _focus={{
        boxShadow: "none",
        outline: "none",
      }}
      w="40px"
      h="40px"
      textAlign="center"
      alignContent="center"
      pt={1.5}
      mt={2}
      {...props}
    >
      {props.children}
    </Box>
  );
}
