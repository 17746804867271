import { Button, Flex } from "@chakra-ui/react";
import { shade } from "polished";

interface ClearButtonProps {
  onClick: () => void;
}

export function ClearButton({ onClick }: ClearButtonProps) {
  return (
    <Flex w="100%" flexDir="row" flexWrap="nowrap" align="center" justify="end">
      <Button
        my={5}
        bg="transparent"
        maxW="120px"
        onClick={onClick}
        color="black"
        fontSize="12px"
        lineHeight="16px"
        fontWeight="600"
        _hover={{
          backgroundColor: shade(0.1, "#FFFAFA"),
        }}
      >
        Limpar Resposta
      </Button>
    </Flex>
  );
}
