import { Box } from "@chakra-ui/react";
import { LikeDislikeRadioCheckbox } from "../../../atoms/Radio/LikeDislikeRadioCheckbox";
import { RadioInput } from "../../../atoms/Radio/RadioInput";

export function LikeDislikeRadioCard(props: any) {
  return (
    <Box as="label">
      <RadioInput {...props} />
      <LikeDislikeRadioCheckbox {...props} />
    </Box>
  );
}
