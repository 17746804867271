import { FormControlFieldset } from "../../atoms/FormControl/FormControlFieldset";
import { FormLabelLegend } from "../../atoms/FormLabel/FormLabelLegend";
import { UnstyledInput } from "../../atoms/Input/UnstyledInput";
import { FormControlQuestionSearch } from "../../molecules/FormControl/FormControlQuestionSearch";

interface ReplicaProps {
  label?: string;
  name: string;
  color: string;
  focus: boolean;
  setValue: (value: any, result: string) => void;
  setFocus: (value: boolean) => void;
  setReplica: (value: string) => void;
  value: number | string;
  replica?: string;
}
export function Replica({
  label,
  name,
  color,
  focus,
  setValue,
  setFocus,
  setReplica,
  value,
  replica,
}: ReplicaProps) {
  return (
    <FormControlQuestionSearch label={label} name={name}>
      <FormControlFieldset bg={color} id={name} error={false} focus={focus}>
        <FormLabelLegend
          htmlFor={name}
          text="Digite sua resposta"
          color={color}
          focus={focus}
        />
        <UnstyledInput
          id="promoter-input"
          name="promoter-input"
          onChange={(e: any) => {
            setValue(value, e.target.value);
            setReplica(e.target.value);
          }}
          defaultValue={replica ?? ""}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
      </FormControlFieldset>
    </FormControlQuestionSearch>
  );
}
