import { SearchColorText } from "../../../components/atoms/SearchColorText";

export function IndexDisabledControl() {
  return (
    <SearchColorText
      text="A pesquisa de satisfação está momentaneamente desabilitada"
      color="#000"
    />
  );
}
