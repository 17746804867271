import { Flex, Image, Link, Text } from "@chakra-ui/react";
import { useMemo } from "react";

interface FooterProps {
  whiteLabel?: {
    name: string;
    logo_vertically_url: string;
  };
}

export function Footer({ whiteLabel }: FooterProps) {
  const selectImage = useMemo(() => {
    if (
      window.location.origin === process.env.REACT_APP_WEB_URL ||
      window.location.origin === process.env.REACT_APP_OTHER_WEB_URL
    ) {
      return (
        <Link href="https://experienciab2s.com/" target="_blank">
          <Image
            src={
              "https://b2s.s3.sa-east-1.amazonaws.com/falae/falae_sem_fundo.png"
            }
            alt="Logo Falaê"
            w="70px"
            mr="10px"
          />
        </Link>
      );
    }

    if (whiteLabel) {
      return (
        <Image
          src={whiteLabel.logo_vertically_url}
          alt={whiteLabel.name}
          w="30px"
          mr="10px"
        />
      );
    }

    return <></>;
  }, [whiteLabel]);

  return (
    <Flex
      justify="space-between"
      flexDir="row"
      align="center"
      mb="10px"
      bottom="0"
      px={{ base: "20px", md: 0 }}
      mt="10px"
    >
      <Text fontSize="10px" lineHeight="24px" ml="10px" color="#A9A9A9">
        Copyright © {new Date().getFullYear()} - Todos os direitos reservados
      </Text>
      {selectImage}
    </Flex>
  );
}
