import { useParams } from "react-router-dom";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { useFormClient } from "../../../hooks/formClient";

import { QRcode } from "../../../components/templates/QRcode";

export function StoreClientQrCodeOnePage() {
  const { search, company } = useFormClient();

  const { slug, search_slug, answer_id } = useParams();

  return (
    <>
      <TitleDefault title={`Cadastro`} />
      <QRcode
        link={`${process.env.REACT_APP_WEB_URL}/formularios/${slug}/${search_slug}/cliente/inicial/${answer_id}`}
        anonymousSearch={search.anonymous_search}
        primaryColor={company.color}
        thanksLink={`/formularios/${slug}/${search_slug}/obrigado`}
      />
    </>
  );
}
