import { FaMeh, FaRegMeh } from "react-icons/fa";

interface MehIconProps {
  selected: number;
}

export function MehIcon({ selected }: MehIconProps) {
  return selected === 3 ? (
    <FaMeh color="#efc62e" size={40} />
  ) : (
    <FaRegMeh color="#efc62e" size={40} />
  );
}
