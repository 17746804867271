import { Icon, Flex, Text } from "@chakra-ui/react";
import { FiGift } from "react-icons/fi";

interface GiftVisitCardProps {
  value: string;
  iconColor?: string;
  onOpen: () => void;
}

export function GiftVisitCard({
  value,
  iconColor = "#D9D9D9",
  onOpen,
}: GiftVisitCardProps) {
  return (
    <>
      <Flex
        onClick={onOpen}
        _hover={{
          cursor: "pointer",
        }}
        alignItems="center"
        flexDir="column"
        justifyContent="space-around"
        borderRadius="14px"
        w={{ base: "55px", md: "60px" }}
        h={{ base: "55px", md: "60px" }}
        bg="white"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15);"
      >
        <Icon as={FiGift} color={iconColor} fontSize="21px" />
        <Text
          fontWeight="bold"
          color="#787878"
          fontSize="16px"
          lineHeight="18px"
        >
          {value}
        </Text>
      </Flex>
    </>
  );
}
