import { Link } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalLinkProps {
  link: string;
  children: ReactNode;
}

export function ExternalLink({ link, children }: ExternalLinkProps) {
  return (
    <Link href={link} _hover={{ textDecoration: false }}>
      {children}
    </Link>
  );
}
