import { Text } from "@chakra-ui/react";

interface SearchTextLinkProps {
  text: string;
}

export function SearchTextLink({
  text,
}: SearchTextLinkProps) {
  return (
    <Text
      fontSize="14px"
      lineHeight="16px"
      fontWeight="600"
      letterSpacing="1.25"
      color="#363636"
    >
      {text}
    </Text>
  );
}
