import { Button } from "@chakra-ui/react";

interface SpinWhellButtonProps {
    onClick: () => void;
}

export function SpinWhellButton({ onClick }: SpinWhellButtonProps) {
    return (
        <Button
            position="absolute"
            width={{ base: "50px", md: "60px", lg: "70px" }}
            height={{ base: "50px", md: "60px", lg: "70px" }}
            background="#fff"
            borderRadius="50%"
            zIndex="10"
            justifyContent="center"
            alignItems="center"
            fontWeight="700"
            color="silver"
            border="2px solid rgba(165, 159, 159, 0.5)"
            padding={{ base: "20px", md: "30px", lg: "40px" }}
            cursor="pointer"
            userSelect="none"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            onClick={onClick}
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.7)"
            fontSize={{ base: "12px", md: "14px", lg: "16px" }}
        >
            Girar
        </Button>
    );
}
