import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SpinWheelRootProps {
    children: ReactNode;
}

export function SpinWheelRoot({ children }: SpinWheelRootProps) {
    return (
        <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            borderRadius="md"
        >
            {children}
        </Flex>
    );
};
