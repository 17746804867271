import { FaFrown, FaRegFrown } from "react-icons/fa";

interface FrownIconProps {
  selected: number;
}

export function FrownIcon({ selected }: FrownIconProps) {
  return selected === 2 ? (
    <FaFrown color="#e27a45" size={40} />
  ) : (
    <FaRegFrown color="#e27a45" size={40} />
  );
}
