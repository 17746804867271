import { Text } from "@chakra-ui/react";
interface SearchColorTextProps {
  text: string;
  color: string;
}

export function SearchColorText({ text, color }: SearchColorTextProps) {
  return (
    <Text color={color} fontSize="14px" lineHeight="18px" textAlign="center">
      {text}
    </Text>
  );
}
