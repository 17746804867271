import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

interface FlexCenterProps {
  children: ReactNode;
}

export function FlexCenter({ children }: FlexCenterProps) {
  return (
    <Flex flexDir="column" justify="center" alignItems="center">
      {children}
    </Flex>
  );
}
