import { Flex, SimpleGrid } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { SubmitButton } from "../../components/atoms/Button/SubmitButton";
import { ReactSelectQuestion } from "../../components/organisms/SelectQuestion/ReactSelectQuestion";
import { Loader } from "../../components/templates/Loader";
import { api } from "../../services/api";

interface Unit {
  id: string;
  name: string;
}

interface Company {
  color: string;
  secondary_color: string;
}

const schema = yup.object().shape({
  company_id: yup.string().required("Empresa é obrigatória"),
});

export function DegustCompaniesGroup() {
  const navigate = useNavigate();
  const { franchise_id, search_invoice_id } = useParams();
  const [units, setUnits] = useState<Unit[]>([]);
  const [company, setCompany] = useState<Company>({} as Company);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    api.get(`clientForms/degust/companies/${franchise_id}`).then((response) => {
      setUnits(response.data.units);
      setCompany(response.data.company);
      setIsLoading(true);
    });
  }, [franchise_id]);

  const { formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleSubmitCompany: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      api
        .post(`clientForms/degust/companies/${franchise_id}`, {
          company_id: formValue.company_id,
          search_invoice_id,
        })
        .then((response) => {
          const { search_id } = response.data;
          localStorage.setItem(`degust-${search_id}`, response.data.id);
          navigate(`/${search_id}`);
        })
        .catch((err) => {
          navigate("/");
        });
    },
    [franchise_id, navigate, search_invoice_id]
  );

  return (
    <Loader isLoading={isLoading}>
      <Flex
        as="form"
        direction="column"
        id="send-form"
        onSubmit={handleSubmit(handleSubmitCompany)}
      >
        <SimpleGrid spacingY={4} columns={1} mt="3">
          <ReactSelectQuestion
            label={"Em qual unidade você realizou o seu pedido?"}
            name="company_id"
            options={units
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((unit) => ({
                name: unit.name,
                value: unit.id,
              }))}
            setValue={(value: string) => {
              setValue("company_id", value);
            }}
            isRequired
          />
        </SimpleGrid>
        <Flex justify="flex-end">
          <SubmitButton
            bg={company.secondary_color}
            color="white"
            text="Enviar"
            form="send-form"
            isLoading={formState.isSubmitting}
          />
        </Flex>
      </Flex>
    </Loader>
  );
}
